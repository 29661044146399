import axios from 'axios';

// const resourse = 'sap/?url=/api_colegio/';

class InvoiceSapService {
  getPaymentDetail = (params) => {
    const response = axios.get('api/evaluacion', { params });
    return response;
  }
}

export default InvoiceSapService;
