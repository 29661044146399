import types from '@/types';
import PaymentService from '../services/PaymentService';
// import ExchangeRateService from '@/services/ExchangeRateService';

const startPayment = (merchantNumber, merchantName, customerName) => {
  const payment = {
    sap_customer: process.env.VUE_APP_SAP_CUSTOMER,
    merchant_number: merchantNumber,
    merchant_name: merchantName,
    sap_customer_name: customerName,
    invoices: [],
    advancepayments: [],
  };
  return payment;
};

const data = {
  payment: startPayment(),
  evaluationPaymentStart: null,
  paymentError: {
    ResponseMessage: '',
  },
  paymentDocument: null,
  payments: [],
  paymentMethod: null,
  paymentDetail: {},
  exchangeRate: null,
};

const theGetters = {
  totalInvoices: (state) => {
    if (state.payment.invoices) {
      return state.payment.invoices.reduce((a, b) => a + parseFloat(b.MONTO_DOP), 0);
    }
    return state.payment.total_invoice_amount;
  },
  totalAdvancePayments: (state) => {
    if (state.payment.advancepayments) {
      return state.payment.advancepayments.reduce((a, b) => a + parseFloat(b.amount), 0);
    }
    return state.payment.total_advancepayment_amount;
  },
  total: (state, getters) => getters.totalInvoices,
  exchangeRate: (state) => (state.exchangeRate ? state.exchangeRate.rate : 0),
  request: (state) => state.payment.request,
  response: (state) => state.payment.response,
};

const mutations = {
  [types.SET_PAYMENT_ATTEMPT](state, payment) {
    state.payment = payment;
  },
  [types.SET_PAYMENT_DOCUMENT](state, paymentDocument) {
    state.paymentDocument = paymentDocument;
  },
  [types.SET_PAYMENT_ERROR](state, paymentError) {
    state.paymentError = paymentError;
  },
  [types.SET_PAYMENT_ATTEMPTS](state, payments) {
    state.payments = payments;
  },
  [types.SET_PAYMENT_METHOD](state, paymentMethod) {
    state.paymentMethod = paymentMethod;
  },
  [types.SET_PAYMENT_DETAIL](state, paymentDetail) {
    state.paymentDetail = paymentDetail;
  },
  [types.SET_EXCHANGE_RATE](state, exchangeRate) {
    state.exchangeRate = exchangeRate;
  },
  [types.SET_EVALUATION_START](state, evaluationPaymentStart) {
    state.evaluationPaymentStart = evaluationPaymentStart;
  },

};

const actions = {
  [types.START_PAYMENT]({ commit }) {
    const merchantNumber = process.env.VUE_APP_MERCHANT;
    const merchantName = process.env.VUE_APP_MERCHANT_NAME;
    commit(types.SET_PAYMENT_ATTEMPT, startPayment(merchantNumber, merchantName));

    const evaluationPayment = new PaymentService();
    evaluationPayment.evaluationPaymentStart(merchantNumber).then((response) => {
      commit(types.SET_EVALUATION_START, response.data[0]);
    });
  },
  [types.SAVE_PAYMENT_ATTEMPT]({ state, commit }) {
    const paymentService = new PaymentService();
    return paymentService.savePaymentAttempt(state.payment)
      .then((response) => {
        commit(types.SET_PAYMENT_ATTEMPT, response.data);
      });
  },
  [types.GET_PAYMENT_ATTEMPT]({ commit }, paymentId) {
    const paymentService = new PaymentService();
    return paymentService.getPaymentAttempt(paymentId)
      .then((response) => {
        commit(types.SET_PAYMENT_ATTEMPT, response.data);
      });
  },
  [types.MAKE_PAYMENT]({ state, commit }, creditCard) {
    const paymentService = new PaymentService();
    commit(types.SET_PAYMENT_METHOD, creditCard.id);
    return paymentService.makePayment(state.payment.id, creditCard)
      .then((response) => {
        commit(types.SET_PAYMENT_METHOD);
        commit(types.SET_PAYMENT_DETAIL, response.data);
        // if (response.data && response.data.data) {
        //   const document = `data:application/pdf;base64,${response.data.data}`;
        //   commit(types.SET_PAYMENT_DOCUMENT, document);
        // }
      });
  },
  /*
  [types.GET_EXCHANGE_RATE]({ commit }) {
    const exchangeRateService = new ExchangeRateService();
    return exchangeRateService.getList()
      .then((response) => {
        commit(types.SET_EXCHANGE_RATE, response.data);
      });
  }, */
};

export default {
  namespaced: true,
  state: data,
  getters: theGetters,
  mutations,
  actions,
};
