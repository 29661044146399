<template>
<div class="bg-login">
  <v-container fluid>
    <v-card
      class="d-flex align-center justify-center overflow-hidden"
      color="transparent"
      flat
      height="80vh"
      tile
      outlined
    >
      <v-card class="px-8 py-6" outlined tile width="360">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title>{{ $t('login.title') }}</v-card-title>
          <v-text-field
            outlined
            v-model="email"
            :rules="emailRules"
            name="email"
            label="E-mail"
            required
          ></v-text-field>
          <v-text-field
            outlined
            v-model="password"
            v-on:keyup.enter="validate"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show ? 'text' : 'password'"
            name="password"
            v-bind:label="$t('login.form.password')"
            v-bind:hint="$t('register.form.password')"
            class="input-group--focused"
            @click:append="show = !show"
          ></v-text-field>
          <v-card-actions class="d-flex justify-space-between pa-0">
            <router-link to="/forgot" class="text-caption">
              {{ $t('login.form.fgotpassword') }}
            </router-link>
            <v-btn :disabled="!valid" color="success" @click="validate">
              Login
            </v-btn>
          </v-card-actions>
          <br/>
          <v-alert
            dense
            dismissible
            outlined
            text
            v-model="alertShow"
            type="error"
          >
          {{ errorMSG }}
          </v-alert>
        </v-form>
      </v-card>
    </v-card>
  </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import i18n from '@/plugins/i18n';

export default {
  name: 'Login',
  data: () => ({
    alertShow: false,
    errorMSG: null,
    valid: true,
    show: false,
    email: '',
    emailRules: [
      (v) => !!v || i18n.t('login.validateFrm.email'),
      (v) => /.+@.+\..+/.test(v) || i18n.t('login.validateFrm.email_valid'),
    ],
    password: '',
    rules: {
      required: (value) => !!value || i18n.t('login.validateFrm.required'),
      min: (v) => v?.length >= 8 || i18n.t('login.validateFrm.min'),
    },
  }),

  computed: {
    ...mapGetters(['user']),
  },

  methods: {
    validate() {
      const vald = this.$refs.form.validate();
      if (vald) {
        this.login();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async login() {
      try {
        const response = await axios.post('api/auth/login', {
          lang: i18n.locale,
          email: this.email,
          password: this.password,
        });

        this.$store.dispatch('user', response.data);
        this.$router.push('/dashboard');
      } catch (error) {
        const { data } = error.response;
        this.alertShow = true;

        if (typeof (data.errors) !== 'undefined') {
          this.errorMSG = data.errors[0].msg;
          return;
        }
        this.errorMSG = data.msg;
      }
    },
  },
};
</script>
<style>
.bg-login {
  background: url('/assets/img/bg-general.jpg')
    no-repeat top center fixed !important;
  background-size: cover !important;
  height: 100%;
}
.bg-login:before {
  content: "";
  position: absolute;
  left: 0; right: 0;
  top: 0; bottom: 0;
  background: rgba(0,0,0,.3);
}
</style>
