<template>
<div class="bg-login">
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
    <v-card
      class="d-flex align-center justify-center overflow-hidden"
      color="transparent"
      flat
      height="80vh"
      tile
    >
      <v-card class="px-8 py-6" outlined tile>
          <v-card-title class="pl-0">{{ $t('register.title') }}</v-card-title>
          <v-row class="mt-0">
            <v-col cols="12" md="6" class="pa-1">
              <v-text-field
                outlined
                v-model="name"
                :rules="[rules.required]"
                v-bind:label="$t('register.form.name')"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-1">
              <v-text-field
                outlined
                v-model="lastname"
                :rules="[rules.required]"
                v-bind:label="$t('register.form.lastname')"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" class="pa-1">
          <v-text-field
            outlined
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" md="6" class="pa-1">
              <v-text-field
                outlined
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show ? 'text' : 'password'"
                name="password"
                v-model="password"
                v-bind:label="$t('register.form.password')"
                v-bind:hint="$t('register.form.password')"
                class="input-group--focused"
                @click:append="show = !show"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-1">
              <v-text-field
                outlined
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min, passwordConfirmationRule]"
                :type="show ? 'text' : 'password'"
                v-model="confirm_password"
                v-bind:label="$t('register.form.repassword')"
                v-bind:hint="$t('register.form.repassword')"
                class="input-group--focused"
                @click:append="show = !show"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions class="d-flex justify-space-between pa-0">
            <v-btn color="info" to="/"> {{ $t('register.form.btnLogin') }}</v-btn>
            <v-btn :disabled="!valid" color="success" @click="validate">
              {{ $t('register.form.register') }}
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-card>
    </v-form>
    <v-snackbar
      centered
      color="#1198C4"
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ textNotify }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</div>
</template>

<script>
import axios from 'axios';
import i18n from '@/plugins/i18n';

export default {
  name: 'Register',
  data: () => ({
    valid: true,
    show: false,
    textNotify: '',
    snackbar: false,
    timeout: 2000,
    name: '',
    lastname: '',
    email: '',
    emailRules: [
      (v) => !!v || i18n.t('register.validateFrm.email'),
      (v) => /.+@.+\..+/.test(v) || i18n.t('register.validateFrm.email_valid'),
    ],
    password: '',
    confirm_password: '',
    rules: {
      required: (value) => !!value || i18n.t('register.validateFrm.required'),
      min: (v) => v?.length >= 8 || i18n.t('register.validateFrm.min'),
    },
  }),

  computed: {
    passwordConfirmationRule() {
      return () => (this.password === this.confirm_password) || i18n.t('register.validateFrm.password_match');
    },
  },

  methods: {
    validate() {
      this.snackbar = true;
      const vald = this.$refs.form.validate();
      if (vald) {
        this.register();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async register() {
      try {
        const response = await axios.post('api/user/', {
          lang: i18n.locale,
          nombre: this.name,
          apellidos: this.lastname,
          email: this.email,
          password: this.password,
        });

        // console.log(response);
        this.textNotify = response.data.msg;
        this.snackbar = true;
        setTimeout(() => {
          this.$router.push('/');
        }, 4000);
      } catch (error) {
        this.errorMSG = error.response.data.msg;
        // console.log(error.response.data);
      }
    },
  },
};
</script>
<style>
.bg-login {
  background: url('/assets/img/bg-general.jpg')
    no-repeat top center fixed !important;
  background-size: cover !important;
  height: 100%;
}
.bg-login:before {
  content: "";
  position: absolute;
  left: 0; right: 0;
  top: 0; bottom: 0;
  background: rgba(0,0,0,.3);
}
</style>
