import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-login"},[_c(VContainer,[_c(VCard,{staticClass:"d-flex align-center justify-center overflow-hidden",attrs:{"color":"transparent","flat":"","height":"80vh","tile":"","outlined":""}},[_c(VCard,{staticClass:"px-8 py-6",attrs:{"outlined":"","tile":"","width":"360"}},[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t('forgotPassword.title')))]),_c(VTextField,{attrs:{"outlined":"","rules":_vm.emailRules,"name":"email","label":"E-mail","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VCardActions,{staticClass:"d-flex justify-space-between pa-0"},[_c(VBtn,{attrs:{"color":"info","to":"/"}},[_vm._v(" "+_vm._s(_vm.$t('register.form.btnLogin')))]),_c(VBtn,{attrs:{"disabled":!_vm.valid,"color":"success"},on:{"click":_vm.validate}},[_vm._v(" "+_vm._s(_vm.$t('forgotPassword.btnSend'))+" ")])],1),_c('br'),_c(VAlert,{attrs:{"dense":"","dismissible":"","outlined":"","text":"","type":"error"},model:{value:(_vm.errorAPI),callback:function ($$v) {_vm.errorAPI=$$v},expression:"errorAPI"}},[_vm._v(" "+_vm._s(_vm.errorMSG)+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }