import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{attrs:{"id":"inspire"}},[_c(VAppBar,{attrs:{"app":"","color":"white","height":"120"}},[_c('div',{staticClass:"d-flex align-center"},[_c('router-link',{attrs:{"to":"/"}},[_c(VImg,{staticClass:"shrink mr-2",attrs:{"alt":"Punta Cana International School","contain":"","max-height":"110","max-width":"110","src":"/assets/img/logo-PCIS.png"}})],1)],1),_c(VSpacer),_c('div',_vm._l((_vm.languages),function(entry){return _c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.$i18n.locale!==entry.language),expression:"$i18n.locale!==entry.language"}],key:entry.title,on:{"click":function($event){return _vm.changeLocale(entry.language)}}},[_c('flag',{attrs:{"iso":entry.flag,"squared":false}}),_vm._v(" "+_vm._s(entry.title)+" ")],1)}),0)],1),_c(VMain,{staticClass:"grey lighten-3"},[_c(VContainer,[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }