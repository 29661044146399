<template>
  <v-app>
      <router-view/>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

import i18n from '@/plugins/i18n';

export default {
  name: 'App',
  components: {

  },
  data() {
    return {
      languages: [
        { flag: 'us', language: 'en', title: 'English' },
        { flag: 'es', language: 'es', title: 'Español' },
      ],
    };
  },
  methods: {
    changeLocale(locale) {
      i18n.locale = locale;
    },
    logout() {
      this.$store.dispatch('user', null);
      this.$router.push('/');
    },
  },
  computed: {
    ...mapGetters(['user']),
  },
};
</script>
<style>
/* body .v-application .brand-primary {
    background-color: rgba(255,255,255,0.8) !important;
} */
</style>
