<template>
 <v-form ref="frmTutor">
        <v-container fluid>
        <v-row>
          <v-col
                cols="12"
                md="3">
                <v-select
                    v-model="estudiante.tipoId"
                    :items="tipoIdOptions"
                    :label="$t('Tipo de ID')+':'"
                    outlined
                    :rules="[rules.required]"
                    return-object
                    ></v-select>
            </v-col>
            <v-col
                cols="12"
                md="3">
                <v-text-field
                    v-model="estudiante.identificacion"
                    :label="$t('Número de Identificación (ID)')+':'"
                    outlined
                    :rules="[rules.required]"
                ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                md="6">
            <v-file-input
            v-model="ZNP_ESTULoad"
            :rules="'error' in this.getSolcupo?[rules.rulesFiles]:[rules.rulesFilesNoReq]"
            :label="$t('Documento de Identificación')"
            outlined
            show-size
            accept="image/*, .pdf"
            prepend-icon="mdi-file-upload"
          ></v-file-input>
          </v-col>
        </v-row>
            <v-row>
            <v-col cols="6" class="pd-4">
                <v-text-field
                    v-model="estudiante.nombre"
                    :label="$t('Nombre')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="estudiante.apellidos"
                    :label="$t('Apellidos')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
             <v-col cols="4">
                 <v-autocomplete
                    v-model="estudiante.nacionalidad"
                    :items="nacionalidadOptions"
                    :label="$t('Nacionalidad')+':'"
                    outlined
                    :rules="[rules.required]"
                    return-object
                    ></v-autocomplete>
            </v-col>
            <v-col cols="4">
                 <v-autocomplete
                    v-model="estudiante.sexo"
                    :items="sexoOptions"
                    :label="$t('Sexo')+':'"
                    outlined
                    :rules="[rules.required]"
                    return-object
                    ></v-autocomplete>
            </v-col>
            <v-col cols="4">
                <v-text-field
                    v-model="estudiante.lugar_nacimiento"
                    :label="$t('Lugar de nacimiento')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
             <!-- <v-col cols="4">
               <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted"
                    label="Fecha de Nacimiento:"
                    outlined
                    prepend-icon="mdi-calendar"
                    readonly
                    @change="estudiante.fecha_nacimiento = parseDate(dateFormatted)"
                    :rules="[rules.required]"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                 v-model="estudiante.fecha_nacimiento"
                 locale="es-es"
                :active-picker.sync="activePicker"
                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
                .toISOString().substr(0, 10)"
                min="1990-01-01"
                @change="save"
              ></v-date-picker>
              </v-menu>
            </v-col> -->
            <v-col cols="4">
                 <v-autocomplete
                    v-model="estudiante.lengua_nativa"
                    :items="idiomasOptions"
                    :label="$t('Lengua nativa')+':'"
                    outlined
                    :rules="[rules.required]"
                    return-object
                    ></v-autocomplete>
            </v-col>
            <v-col cols="4">
                 <v-autocomplete
                    v-model="estudiante.otro_idioma"
                    :items="idiomasOptions"
                    :label="$t('Otro idioma')+':'"
                    outlined
                    return-object
                    ></v-autocomplete>
            </v-col>
        </v-row>
        <!-- Validar Grado para no mostrar colegio a los que ha asistido -->
         <v-row dv-if="!gradoEscolarSl || gradoEscolarSl !== 'Kkkk'">
            <!-- <v-col>
                <v-card-title class="text-h5 blue lighten-5">
                    Colegio a los que ha asistido:
                </v-card-title>
            </v-col> -->
            <v-col>
              <v-card-title class="hermanos text-h5 blue lighten-5">
                  {{$t('Ha estado su hijo(a) actualmente incristo en un')}}
                      <v-switch
                       class="pl-5"
                        v-model="estudiante.incrito_educativo"
                        :label="` ${$t('centro educativo?')} ${estudiante.incrito_educativo ?
                        $t('Si'):'No'}`"
                        color="info"
                        persistent-hint
                      ></v-switch>
            </v-card-title>
              </v-col>
        </v-row>
        <template v-if="estudiante.incrito_educativo">
        <v-row>
          <v-col cols="6">
            <v-file-input
            v-model="ZPCIS_RECNLoad"
            :rules="'error' in this.getSolcupo?[rules.rulesFiles]:[rules.rulesFilesNoReq]"
            :label="$t('Record de Notas')"
            outlined
            show-size
            accept="image/*, .pdf"
            prepend-icon="mdi-file-upload"
          ></v-file-input>
          </v-col>
          <v-col cols="6">
            <v-file-input
            v-model="ZPCIS_CSLoad"
            :rules="'error' in this.getSolcupo?[rules.rulesFiles]:[rules.rulesFilesNoReq]"
            :label="$t('Carta de Saldo')"
            outlined
            show-size
            accept="image/*, .pdf"
            prepend-icon="mdi-file-upload"
          ></v-file-input>
          </v-col>
        </v-row>
        <v-row v-for="(input,k) in inputColegios.slice(0,10)" :key="k">
          <v-col cols="2">
                 <v-text-field
                    :prepend-icon="(k || ( !k && inputColegios.length > 1))? 'mdi-minus-circle':''"
                    @click:prepend="removeColegio(k)"
                    v-model="input.colegio"
                    :label="$t('Nombre del Colegio')+':'"
                    :rules="[rules.required]"
                    outlined
                    v-uppercase
                ></v-text-field>
            </v-col>
            <v-col cols="2">
                 <v-autocomplete
                    v-model="input.pais"
                    :items="paisOptions"
                    :label="$t('País')+':'"
                    :rules="[rules.required]"
                    outlined
                    return-object
                    ></v-autocomplete>
            </v-col>
            <v-col cols="2">
                 <v-text-field
                    v-model="input.ciudad"
                    :label="$t('Ciudad')+':'"
                    :rules="[rules.required]"
                    outlined
                    v-uppercase
                ></v-text-field>
            </v-col>
             <v-col cols="2" id="grado">
                <v-autocomplete
                    v-model="input.grado"
                    :items="gradoptions"
                    :label="$t('Grado Escolar')+':'"
                    :rules="[rules.required]"
                    outlined
                    return-object
                    ></v-autocomplete>
            </v-col>
            <v-col cols="2">
                 <v-text-field
                    v-model="input.fechaEntrada"
                    :label="$t('Fecha entrada')+':'"
                    :rules="[rules.required]"
                    prepend-icon="mdi-calendar"
                    outlined
                    v-mask="'##/##/####'"
                    :hint="$t('DIA/MES/AÑO')"
                    persistent-hint
                ></v-text-field>
            </v-col>
            <v-col cols="2">
                 <v-text-field
                    :append-outer-icon="k == inputColegios.length-1 ? 'mdi-plus-circle':''"
                    @click:append-outer="addColegio"
                    v-model="input.FechaSalida"
                    :label="$t('Fecha salida')+':'"
                    :rules="[rules.required]"
                    prepend-icon="mdi-calendar"
                    outlined
                    v-mask="'##/##/####'"
                    :hint="$t('DIA/MES/AÑO')"
                    persistent-hint
                ></v-text-field>
            </v-col>
        </v-row>
        </template>
        <v-row>
              <v-col>
              <v-card-title class="hermanos text-h5 blue lighten-5">
                  {{$t('Hermanos del estudiante')}}:
                      <v-switch
                       class="pl-5"
                        v-model="estudiante.hermanos"
                        :label="`${$t('Tiene hermano(s)')}: ${estudiante.hermanos?$t('Si'):'No'}`"
                        color="info"
                        persistent-hint
                      ></v-switch>
            </v-card-title>
              </v-col>
        </v-row>
        <template v-if="estudiante.hermanos">
       <v-row v-for="(input,k) in inputHermanos.slice(0,10)" :key="k">
         <v-col cols="4">
                 <v-text-field
                    :prepend-icon="(k || ( !k && inputHermanos.length > 1))? 'mdi-minus-circle':''"
                    @click:prepend="removeHermano(k)"
                    v-model="input.nombre"
                    :label="$t('Nombre')+':'"
                    :rules="[rules.required]"
                    outlined
                    v-uppercase
                ></v-text-field>
          </v-col>
          <v-col cols="4">
                 <v-text-field
                    v-model="input.colegio"
                    :label="$t('Colegio')+':'"
                    :rules="[rules.required]"
                    outlined
                    v-uppercase
                ></v-text-field>
          </v-col>
          <v-col cols="2">
                <v-autocomplete
                    v-model="input.grado"
                    :items="[ ...gradoptions, { text: 'No aplica', value: 'n/a' }]"
                    :label="$t('Grado Escolar')+':'"
                    :rules="[rules.required]"
                    outlined
                    return-object
                    ></v-autocomplete>
          </v-col>
          <v-col cols="2">
                 <v-autocomplete
                    :append-outer-icon="k == inputHermanos.length-1 ? 'mdi-plus-circle':''"
                    @click:append-outer="addHermanos"
                    v-model="input.idioma"
                    :items="idiomasOptions"
                    :label="$t('Idioma')+':'"
                    :rules="[rules.required]"
                    outlined
                    return-object
                    ></v-autocomplete>
            </v-col>
       </v-row>
       </template>
        </v-container>
        </v-form>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'DatosEstudiante',
  props: {
    gradoEscolarSl: String,
  },
  data() {
    return {
      fecha_nacimiento2: '',
      // fecha_nacimiento: (new Date(
      //   Date.now() - (new Date()).getTimezoneOffset() * 60000,
      // ))
      //   .toISOString().substr(0, 10),
      dateFormatted2: '',
      dateFormatted: '',
      activePicker: null,
      menu: false,
      menu1: false,
      paisOptions: [],
      nacionalidadOptions: [],
      idiomasOptions: [],
      gradoptions: [],
      gradosOptionsHardCode: [
        {
          CENTRO: 'PCIS',
          GRADOESC: '1',
          DESCRIPCION: '1ST',
        },
        {
          CENTRO: 'PCIS',
          GRADOESC: '2',
          DESCRIPCION: '2ND',
        },
        {
          CENTRO: 'PCIS',
          GRADOESC: '3',
          DESCRIPCION: '3RD',
        },
        {
          CENTRO: 'PCIS',
          GRADOESC: '4',
          DESCRIPCION: '4TH',
        },
        {
          CENTRO: 'PCIS',
          GRADOESC: '5',
          DESCRIPCION: '5TH',
        },
        {
          CENTRO: 'PCIS',
          GRADOESC: '6',
          DESCRIPCION: '6TH',
        },
        {
          CENTRO: 'PCIS',
          GRADOESC: '7',
          DESCRIPCION: '7TH',
        },
        {
          CENTRO: 'PCIS',
          GRADOESC: '8',
          DESCRIPCION: '8TH',
        },
        {
          CENTRO: 'PCIS',
          GRADOESC: '9',
          DESCRIPCION: '9TH',
        },
        {
          CENTRO: 'PCIS',
          GRADOESC: '10',
          DESCRIPCION: '10TH',
        },
        {
          CENTRO: 'PCIS',
          GRADOESC: '11',
          DESCRIPCION: '11TH',
        },
        {
          CENTRO: 'PCIS',
          GRADOESC: '12',
          DESCRIPCION: '12TH',
        },
        {
          CENTRO: 'PCIS',
          GRADOESC: 'K',
          DESCRIPCION: 'Kinder',
        },
        {
          CENTRO: 'PCIS',
          GRADOESC: 'P',
          DESCRIPCION: 'Pre-Kinder',
        },
        {
          CENTRO: 'PCIS',
          GRADOESC: 'T1',
          DESCRIPCION: 'Toddler I',
        },
        {
          CENTRO: 'PCIS',
          GRADOESC: 'T2',
          DESCRIPCION: 'Toddler II',
        },
      ],
      sexoOptions: [
        { text: 'MASCULINO', value: 'M' },
        { text: 'FEMENINO', value: 'F' },
      ],
      ZNP_ESTULoad: [],
      ZPCIS_CSLoad: [],
      ZPCIS_RECNLoad: [],
      estudiante: {
        tipoId: '',
        identificacion: '',
        nacionalidad: '',
        nacionalidad_desc: '',
        sexo: '',
        lengua_nativa: '',
        otro_idioma: '',
        fecha_nacimiento: null,
        hermanos: false,
        incrito_educativo: true,
        nombre: '',
        apellidos: '',
        lugar_nacimiento: '',
        ZNP_ESTU: null,
        ZPCIS_CS: null,
        ZPCIS_RECN: null,
      },
      inputColegios: [
        {
          colegio: '',
          pais: '',
          ciudad: '',
          grado: '',
          fechaEntrada: '',
          FechaSalida: '',
        },
      ],
      inputHermanos: [
        {
          nombre: '',
          colegio: '',
          grado: '',
          idioma: '',
        },
      ],
      tipoIdOptions: [
        { text: 'Cédula / NUI', value: 1 },
        { text: 'Pasaporte', value: 2 },
      ],
      rules: {
        required: (value) => !!value || this.$t('login.validateFrm.required'),
        rulesFilesNoReq: (value) => {
          if (value !== null && typeof value.name !== 'undefined') {
            const validExt = ['jpg', 'jpeg', 'png', 'pdf'];
            const extFile = value.name.split('.').pop().toLowerCase();
            if (validExt.includes(extFile)) {
              if (value.size < 2000000) {
                return true;
              }
              return 'El archivo debe ser inferior a 2 MB.';
            }
            return `Archivo no valido formatos permitidos ${validExt.join(', ')}`;
          }
          return true;
        },
        rulesFiles2: (value) => {
          if (typeof value !== 'undefined') {
            const validExt = ['jpg', 'jpeg', 'png', 'pdf'];
            const extFile = value !== null ? value.name.split('.').pop().toLowerCase() : '';
            if (validExt.includes(extFile)) {
              if (value.size < 2000000) {
                return true;
              }
              return 'El archivo debe ser inferior a 2 MB.';
            }
            return `Archivo no valido formatos permitidos ${validExt.join(', ')}`;
          }
          if (!this.getSolcupo.error) { return true; }
          return 'Este campo es obligatorio.';
        },
        rulesFiles: (value) => {
          if (value !== null && typeof value.name !== 'undefined') {
            const validExt = ['jpg', 'jpeg', 'png', 'pdf'];
            const extFile = value.name.split('.').pop().toLowerCase();
            if (validExt.includes(extFile)) {
              if (value.size < 2000000) {
                return true;
              }
              return this.$t('El archivo debe ser inferior a 2 MB.');
            }
            return `${this.$t('Archivo no valido formatos permitidos')} ${validExt.join(', ')}`;
          }
          return this.$t('login.validateFrm.required');
        },
      },
    };
  },
  methods: {
    handleImage(file, prodName) {
      if (file) {
        this.createBase64Images(file, prodName);
      }
      return false;
    },
    async createBase64Images(imageObject, prodName) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.estudiante[prodName] = {
          fileExt: imageObject.type.split('/')[1].toUpperCase(),
          fileBase64: e.target.result,
        };
      };
      await reader.readAsDataURL(imageObject);
    },
    async getDataForm() {
      // Obtener los valores del formulario
      try {
        const { PAISES, IDIOMA } = this.datosfrm;
        // Paises options
        const arrPaises = PAISES.map((pais) => ({ text: pais.LANDX, value: pais.LAND1 }));
        arrPaises.sort((a, b) => {
          const nameA = a.text.toUpperCase(); // ignore upper and lowercase
          const nameB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        this.paisOptions = arrPaises;

        // Nacionalidad options
        const arrNac = PAISES.map((v) => ((v.NATIO !== '')
          ? { text: `${v.NATIO} - ${v.LAND1}`, value: v.LAND1 }
          : { text: v.NATIO, value: v.LAND1, disabled: true }));
        arrNac.sort((a, b) => {
          const nameA = a.text.toUpperCase(); // ignore upper and lowercase
          const nameB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        this.nacionalidadOptions = arrNac;

        // Prefesion options
        const arrProf = IDIOMA.map((v) => ((v.SPTXT !== '')
          ? { text: v.SPTXT, value: v.SPRSL }
          : { text: v.SPTXT, value: v.SPRSL, disabled: true }));
        arrProf.sort((a, b) => {
          const nameA = a.text.toUpperCase(); // ignore upper and lowercase
          const nameB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        this.idiomasOptions = arrProf;

        //   console.log(arrPaises);
        // console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async getColaborador() {
      // Obtener los valores del formulario
      try {
        this.loading = true;
        this.dialog = true;
        const { data } = await axios.get(`api/formadmission/searchcliente?type=${this.estudiante.tipoId}&identification=${this.estudiante.identificacion}`);
        console.log(data);
        // this.tutor.nombre = data.FIRST_NAME;
        // this.tutor.apellidos = data.LAST_NAME;
        // this.tutor.nacionalidad = data.NATIONALITY;
        // this.tutor.direccion.pais = data.ADDRESS.COUNTRY;
        // this.tutor.direccion.calle = data.ADDRESS.STREET;
        // this.tutor.direccion.numero = data.ADDRESS.HOUSE_NO;
        // if (data.EMAIL) this.tutor.email = data.EMAIL;
      } catch (error) {
        this.errorMSG = 'No encontrado.';
        console.log(error.response.status);
        // this.snackbar = true;
        console.log(error);
      }
      setTimeout(() => {
        this.loading = false;
        this.dialog = false;
      }, 3000);
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    appendIconCallback() {
      // eslint-disable-next-line no-alert
      alert('click:append');
    },
    addColegio() {
      this.inputColegios.push({
        colegio: '',
        pais: '',
        ciudad: '',
        grado: '',
        fechaEntrada: '',
        FechaSalida: '',
      });
    },
    addHermanos() {
      this.inputHermanos.push({
        nombre: '',
        colegio: '',
        grado: '',
        idioma: '',
      });
    },
    removeColegio(index) {
      this.inputColegios.splice(index, 1);
    },
    removeHermano(index) {
      this.inputHermanos.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters({ datosfrm: 'getDataForm' }),
    ...mapGetters(['getSolcupo']),
    computedDateFormatted() {
      return this.formatDate(this.estudiante.fecha_nacimiento);
    },
  },
  watch: {
    datosfrm() {
      this.getDataForm();
    },
    'estudiante.fecha_nacimiento': function nacimiento() {
      this.dateFormatted = this.formatDate(this.estudiante.fecha_nacimiento);
    },
    'estudiante.hermanos': function nacimiento(val) {
      if (!val) {
        this.inputHermanos = [{
          nombre: '',
          colegio: '',
          grado: '',
          idioma: '',
        }];
      }
    },
    menu(val) {
      // eslint-disable-next-line no-return-assign
      return val && setTimeout(() => (this.activePicker = 'YEAR'));
    },
    ZNP_ESTULoad(file) {
      this.handleImage(file, 'ZNP_ESTU'); // documento identificacion del estudiante
    },
    ZPCIS_CSLoad(file) {
      this.handleImage(file, 'ZPCIS_CS'); // documento carta de saldo
    },
    ZPCIS_RECNLoad(file) {
      this.handleImage(file, 'ZPCIS_RECN'); // documento record de notas
    },
  },
  mounted() {
    this.getDataForm();

    const key = 'GRADOESC';

    const grado = [...new Map(this.gradosOptionsHardCode.map(
      (item) => [item[key], item],
    )).values()];

    /* grado.sort((a, b) => {
          const nameA = parseInt(a.GRADOESC, 10); // ignore upper and lowercase
          const nameB = parseInt(b.GRADOESC, 10); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });  */

    this.gradoptions = grado.map((val) => ({
      text: val.DESCRIPCION, value: val.GRADOESC,
    }));
    // this.gradoptions.push({ text: 'No aplica', value: 'n/a' });
  },
};
</script>

<style scoped>
.hermanos {
  padding: 0 !important;
  padding-left: 16px !important;
}
#grado .v-input__icon--append-outer button.mdi:before{
  color: blue !important;
}
</style>
