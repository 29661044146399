/* eslint-disable camelcase */
import types from '@/types';

const emptyCard = () => {
  const card = {
    id: 0,
    name: '',
    number: '',
    expiration: '',
    cvc: '',
    save: false,
  };
  return card;
};

const data = {
  card: emptyCard(),
  cards: [
  ],
};

const getters = {
  noCreditCards: (state) => state.cards.length === 0,
  cardsForSelect: (state) => {
    const cards = [
      { id: 0, name: 'Tarjeta de crédito' },
    ];
    state.cards.forEach((item) => {
      cards.push(item);
    });
    return cards;
  },
};

const mutations = {
  [types.SET_CREDIT_CARD](state, card) {
    state.card = card;
  },
};

const actions = {
  [types.RESET_CREDIT_CARD]({ commit }) {
    commit(types.SET_CREDIT_CARD, emptyCard());
  },
};

export default {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};
