<template>
<div>
  <h1>Dashboard {{ user.usuario.email }}</h1>
</div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'Dashboard',
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
};
</script>
<style>
#app {
  background: white;
}
</style>
