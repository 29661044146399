<!-- eslint-disable max-len -->
<template>
<div>
  <v-toolbar
    dark
    color="blue darken-4"
  >
    <v-toolbar-title>Estudiante</v-toolbar-title>
    <v-autocomplete
      v-model="select"
      :loading="loading"
      :items="items"
      @change="selectEstud"
      cache-items
      class="mx-4"
      flat
      hide-no-data
      hide-details
      label="Seleccione el alumno..."
      solo-inverted
    ></v-autocomplete>
    <!-- <v-btn color="success" @click="handleClick()">
      <v-icon>mdi-magnify</v-icon> Buscar
    </v-btn> -->
  </v-toolbar>
<!-- <object v-if="dataPdf" :data="dataPdf" width="100%" height="450" type="application/pdf"></object> -->
<vue-pdf-embed v-if="dataPdf" :source="dataPdf" style="width: 100%" />
<div>
<v-form ref="frmFirma">
  <v-container no-gutters fluid v-if="dataPdf && !saved" class="blue darken-4">
  <v-row>
      <v-col cols="12"  md="6">
          <v-text-field
                    v-model="tutor"
                    :label="$t('Nombre completo del tutor')+':'"
                    outlined
                    rules555="[rules.required]"
                    class="grey darken-3"
                    dark
                    hide-details="auto"
                    dense
                    v-uppercase
                ></v-text-field>
                  <v-col>
                <span style="color: white;">Acepto</span><br/>
                <v-radio-group
                v-model="acepto"
                row
                dark
                rules222="[rules.required]"
                >
                <v-radio
                    :label="$t('Si')"
                    :value="true"
                ></v-radio>
                <v-radio
                    label="No"
                    :value="false"
                ></v-radio>
                </v-radio-group>
            </v-col>
            </v-col>
      <v-col cols="12" md="6">
      <div class="container-sig">
        <div class="white">
        <vueSignature
            ref="signature"
            :sigOption="option"
            :w="'100%'"
            :h="'100px'"
          ></vueSignature></div>
          <div class="text-right">
          <v-btn class="ma-2" color="success" @click="save">Guardar</v-btn>
          <v-btn class="ma-2" color="info" @click="undo">Deshacer</v-btn>
          <v-btn class="ma-2" color="error" @click="clear">Borrar</v-btn>
          </div>
      </div>
      </v-col>
</v-row>
</v-container>
</v-form>
</div>
<v-dialog
      v-model="loading"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <template>
  <v-row justify="center">
    <v-dialog
      v-model="showModal"
      persistent
      max-width="300"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{$t('Mensaje')}}
        </v-card-title>
        <v-card-text>
            {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="showModal = false; message = null"
          >
            {{$t('Cerrar')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
</div>
</template>
<script>
import axios from 'axios';
import vueSignature from 'vue-signature';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

export default {
  components: {
    vueSignature,
    VuePdfEmbed,
  },
  data() {
    return {
      loading: false,
      showModal: false,
      saved: false,
      estudiantes: [],
      items: [],
      search: null,
      select: null,
      message: null,
      tutor: '',
      acepto: null,
      option: {
        penColor: 'rgb(0, 0, 0)',
        backgroundColor: 'transparent',
        // backgroundColor: 'rgb(255,255,255)',
      },
      disabled: false,
      dataPdf: '',
    };
  },
  watch: {
    search(val) {
      // eslint-disable-next-line no-unused-expressions
      val && val !== this.select && this.querySelections(val);
    },
  },
  methods: {
    async selectEstud(idinscripcion) {
      // console.log(idinscripcion);

      this.loading = true;
      this.saved = false;
      this.tutor = '';
      this.acepto = null;
      try {
        // const { data } = await axios.get(`api/formadmission?lang=${i18n.locale}`);

        const { data } = await axios.get(`api/libros/document?idinscripcion=${idinscripcion}`);
        // this.dataPdf = data.BASE64_FILE.replace('data:document/pdf;', 'data:application/pdf;');
        this.dataPdf = data.BASE64_FILE;
        // this.message = data.MENSAJE;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getDataForm() {
      // Obtener los valores del formulario
      this.loading = true;
      try {
        // const { data } = await axios.get(`api/formadmission?lang=${i18n.locale}`);

        const { data } = await axios.get('api/libros');
        // this.inputData = data;
        this.estudiantes = data;
      } catch (error) {
        console.log(error);
      }

      // Obtener los valores del formulario
      try {
        // Paises options
        const arrEstud = this.estudiantes.map(
          (estudiante) => (
            { text: `${estudiante.IDINSCRIPCION} - ${estudiante.NOMBRES_ESTUD} - (${estudiante.DESC_GRADO})`, value: estudiante.IDINSCRIPCION }),
        );
        arrEstud.sort((a, b) => {
          const nameA = a.text.toUpperCase(); // ignore upper and lowercase
          const nameB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        this.items = arrEstud;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    querySelections(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.states.filter((e) => (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1);
        this.loading = false;
      }, 500);
    },
    async save() {
      // eslint-disable-next-line no-underscore-dangle
      const _this = this;
      const png = _this.$refs.signature.save();
      // const jpeg = _this.$refs.signature.save('image/jpeg');
      // const svg = _this.$refs.signature.save('image/svg+xml');

      // console.log(jpeg);
      // console.log(svg);

      if (this.tutor === null || this.tutor.length === 0) {
        this.message = 'El nombre del tutor es obligatorio';
        this.showModal = true;
      } else if (this.acepto === null) {
        this.message = 'Por favor, debe especificar si acepta o no';
        this.showModal = true;
      } else {
        this.loading = true;
        try {
        // const { data } = await axios.get(`api/formadmission?lang=${i18n.locale}`);

          const dataForm = JSON.stringify({
            base64_file: this.dataPdf.replace('data:application/pdf;base64,', ''),
            firma: png,
            idinscripcion: this.select,
            tutor_nom: this.tutor,
            acepto: this.acepto,
          });

          const { data } = await axios.post('api/libros/document', dataForm);
          // this.dataPdf = data.BASE64_FILE.replace('data:document/pdf;', 'data:application/pdf;');
          // this.dataPdf = data.BASE64_FILE;
          // console.log(data);
          const fileBase64 = data.BASE64_FILE ? data.BASE64_FILE : data.msg.BASE64_FILE;
          this.dataPdf = `data:application/pdf;base64,${fileBase64}`;
          this.message = data.MENSAJE ? data.MENSAJE : data.msg.MENSAJE;
          this.showModal = true;
          this.saved = true;
        } catch (error) {
          console.log(error);
        } finally {
          this.select = null;
          this.tutor = '';
          this.acepto = null;
          this.clear();
          this.loading = false;
        }

        // console.log(png);
      }
    },
    clear() {
      // eslint-disable-next-line no-underscore-dangle
      const _this = this;
      _this.$refs.signature.clear();
    },
    undo() {
      // eslint-disable-next-line no-underscore-dangle
      const _this = this;
      _this.$refs.signature.undo();
    },
  },
  async mounted() {
    this.getDataForm();
  },
};
</script>

<style lang="scss" scoped>

</style>
