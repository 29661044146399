<template>
<v-form ref="frmTutor">
        <v-container fluid>
        <v-row>
          <v-col cols="3">
                <v-autocomplete
                    v-model="infodesarrollo.embarazo"
                    :items="[
                        { text: $t('Planificado'), value: 'planificado'},
                        { text: $t('No planificado'), value: 'no planificado'}
                        ]"
                    :label="$t('Embarazo')+':'"
                    outlined
                    :rules="[rules.required]"
                    ></v-autocomplete>
            </v-col>
            <v-col cols="2">
                <v-autocomplete
                    v-model="infodesarrollo.abortos"
                    :label="$t('Abortos')+':'"
                    :items="[
                        { text: $t('Si'), value: 'si'},
                        { text: 'No', value: 'no'}
                        ]"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-autocomplete>
            </v-col>
            <!-- <v-col cols="3">
                <v-text-field
                    v-model="infodesarrollo.tipo_parto"
                    label="Tipo de Parto:"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col> -->
            <!-- <v-col cols="3">
                <v-text-field
                    v-model="infodesarrollo.tiempo_labor"
                    label="Tiempo de labor:"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col> -->
            <v-col cols="4">
                <v-text-field
                    v-model="infodesarrollo.inicio_lenguaje"
                    :label="$t('Inicio del lenguaje')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
            <v-col cols="3">
                <v-text-field
                    v-model="infodesarrollo.peso"
                    :label="$t('Peso')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
          <!-- <v-col cols="2">
                <v-text-field
                    v-model="infodesarrollo.tamano"
                    label="Tamaño:"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col> -->
            <v-col cols="3">
                <v-autocomplete
                    v-model="infodesarrollo.lloro"
                    :items="[
                        { text: $t('Si'), value: 'si'},
                        { text: 'No', value: 'no'}
                        ]"
                    :label="$t('Lloró')+':'"
                    outlined
                    :rules="[rules.required]"
                    ></v-autocomplete>
            </v-col>
            <v-col cols="3">
                <v-autocomplete
                    v-model="infodesarrollo.leche_maternal"
                    :items="[
                        { text: $t('Si'), value: 'si'},
                        { text: 'No', value: 'no'}
                        ]"
                    :label="$t('Leche maternal')+':'"
                    outlined
                    :rules="[rules.required]"
                    ></v-autocomplete>
            </v-col>
            <v-col cols="3">
                <v-text-field
                    v-model="infodesarrollo.leche_maternal_tiempo"
                    :label="$t('Tiempo')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
            <v-col cols="3">
                <v-autocomplete
                    v-model="infodesarrollo.gateo"
                    :label="$t('Gateó')+':'"
                    :items="[
                        { text: $t('Si'), value: 'si'},
                        { text: 'No', value: 'no'}
                        ]"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>

          <v-col cols="3">
                <v-autocomplete
                    v-model="infodesarrollo.arrastro"
                    :label="$t('Arrastró')+':'"
                    outlined
                    :rules="[rules.required]"
                    :items="[
                        { text: $t('Si'), value: 'si'},
                        { text: 'No', value: 'no'}
                        ]"
                    v-uppercase
                ></v-autocomplete>
            </v-col>
          <v-col cols="3">
                <v-autocomplete
                    v-model="infodesarrollo.camino"
                    :label="$t('Caminó')+':'"
                    outlined
                    :rules="[rules.required]"
                    :items="[
                        { text: $t('Si'), value: 'si'},
                        { text: 'No', value: 'no'}
                        ]"
                    v-uppercase
                ></v-autocomplete>
            </v-col>
          <v-col cols="3">
                <v-select
                    v-model="infodesarrollo.esfinter"
                    :label="$t('Control de esfínter')+':'"
                    attach
                    outlined
                    multiple
                    :items="[
                        { text: $t('Orina'), value: 'Orina'},
                        { text: $t('Heces Fecales'), value: 'Heces Fecales'}
                        ]"
                    v-uppercase
                ></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <v-textarea
                v-model="infodesarrollo.habitos_comer"
                outlined
                :rules="[rules.required]"
                auto-grow
                row-height="25"
                rows="3"
                :label="$t('Hábitos para comer')+':'"
                v-uppercase
                ></v-textarea>
            </v-col>
            <v-col cols="12"  md="6">
                <v-textarea
                v-model="infodesarrollo.habitos_dormir"
                outlined
                :rules="[rules.required]"
                auto-grow
                row-height="25"
                rows="3"
                :label="$t('Hábitos para dormir')+':'"
                v-uppercase
                ></v-textarea>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-textarea
                v-model="infodesarrollo.alergias"
                outlined
                :rules="[rules.required]"
                auto-grow
                row-height="25"
                rows="3"
                :label="$t('Alergias')+':'"
                v-uppercase
                ></v-textarea>
            </v-col>
         </v-row>
         <v-row>
            <v-col cols="12" md="6">
                <v-textarea
                v-model="infodesarrollo.visitando_esp"
                outlined
                :rules="[rules.required]"
                auto-grow
                row-height="25"
                rows="3"
                :label="$t('Visitando un especialista?')+':'"
                v-uppercase
                ></v-textarea>
            </v-col>
            <v-col cols="12" md="6">
                <v-textarea
                v-model="infodesarrollo.actividades_disgustan"
                outlined
                :rules="[rules.required]"
                auto-grow
                row-height="25"
                rows="3"
                :label="$t('Actividades que le disgustan')+':'"
                v-uppercase
                ></v-textarea>
            </v-col>
            <!-- <v-col cols="12"  md="6">
                <v-textarea
                v-model="infodesarrollo.actividades_disfruta"
                outlined
                :rules="[rules.required]"
                auto-grow
                row-height="25"
                rows="3"
                label="Actividades que disfruta:"
                v-uppercase
                ></v-textarea>
            </v-col> -->
        </v-row>
        <!-- <v-row>
            <v-col cols="12"  md="6">
                <v-textarea
                v-model="infodesarrollo.relacion_hermanos"
                outlined
                auto-grow
                row-height="25"
                rows="3"
                label="Relación con sus hermanos:"
                v-uppercase
                ></v-textarea>
            </v-col>
        </v-row> -->
        <v-row>
            <v-col cols="12">
                <v-textarea
                v-model="infodesarrollo.relacion_padres"
                outlined
                :rules="[rules.required]"
                auto-grow
                row-height="25"
                rows="3"
                :label="$t('Relación con sus padres')+':'"
                v-uppercase
                ></v-textarea>
            </v-col>
         </v-row>
        <v-row>
            <v-col cols="12">
                <v-textarea
                v-model="infodesarrollo.narre_situacion_marcado"
                outlined
                :rules="[rules.required]"
                auto-grow
                row-height="25"
                rows="3"
                :label="$t('Narre algua situación que haya marcado la vida escolar de su hijo (a)')
                +':'"
                v-uppercase
                ></v-textarea>
            </v-col>
         </v-row>
        <v-row>
            <v-col cols="12" md="6">
                {{$t(`Su hijo ha asistido a algún terapeuta / profesional de la
                salud mental ( psicólogo, psiquiatra ) ?`)}}<br/>
                <v-radio-group
                v-model="infodesarrollo.asistido_terapeuta"
                row
                :rules="[rules.required]"
                >
                <v-radio
                    :label="$t('Si')"
                    value="si"
                ></v-radio>
                <v-radio
                    label="No"
                    value="no"
                ></v-radio>
                </v-radio-group>
              <v-textarea
                v-if="infodesarrollo.asistido_terapeuta === 'si'"
                v-model="infodesarrollo.asistido_terapeuta_especificar"
                outlined
                :rules="[rules.required]"
                auto-grow
                row-height="25"
                rows="3"
                :label="$t('Por favor, especificar el motivo y si aún continúa asistiendo')+':'"
                v-uppercase
                ></v-textarea>
            </v-col>
            <v-col cols="12" md="6">
                {{$t(`¿Permite al PCIS contactar al terapeuta
                       o especialista de su hijo(a) ?`)}}<br/>
                <v-radio-group
                v-model="infodesarrollo.contactar_terapeuta"
                row
                :rules="[rules.required]"
                >
                <v-radio
                    :label="$t('Si')"
                    value="si"
                ></v-radio>
                <v-radio
                    label="No"
                    value="no"
                ></v-radio>
                </v-radio-group>
              <v-textarea
                v-if="infodesarrollo.contactar_terapeuta === 'si'"
                v-model="infodesarrollo.contactar_terapeuta_informacion"
                outlined
                :rules="[rules.required]"
                auto-grow
                row-height="25"
                rows="3"
                :label="$t('Por favor, provea la información de contacto')+':'"
                v-uppercase
                ></v-textarea>
            </v-col>
        </v-row>
        </v-container>
        </v-form>
</template>

<script>
// import axios from 'axios';
// import { mapGetters } from 'vuex';

export default {
  name: 'frmDesarrollo',
  data() {
    return {
      loading: false,
      dialog: false,
      snackbar: false,
      errorMSG: '',
      infodesarrollo: {
        embarazo: '',
        abortos: '',
        tiempo_labor: '',
        inicio_lenguaje: '',
        peso: '',
        tamano: '',
        lloro: '',
        leche_maternal: '',
        leche_maternal_tiempo: '',
        gateo: '',
        arrastro: '',
        camino: '',
        esfinter: '',
        habitos_comer: '',
        habitos_dormir: '',
        alergias: '',
        visitando_esp: '',
        actividades_disgustan: '',
        relacion_padres: '',
        narre_situacion_marcado: '',
        asistido_terapeuta: '',
        asistido_terapeuta_especificar: '',
        contactar_terapeuta: '',
        contactar_terapeuta_informacion: '',
      },
      emailRules: [
        (v) => !!v || this.$t('login.validateFrm.email'),
        (v) => /.+@.+\..+/.test(v) || this.$t('login.validateFrm.email_valid'),
      ],
      rules: {
        required: (value) => !!value || this.$t('login.validateFrm.required'),
      },
    };
  },
};
</script>
