export default {
  path: '/',
  component: () => import(/* webpackChunkName: "AppLayout" */ '@/views/layout/App.vue'),
  children: [
    {
      path: 'evaluacion/:idpay',
      component: () => import(/* webpackChunkName: "quickPaySolcupo" */ '../views/MakePaymentLayout.vue'),
      name: 'paymentSolicitud',
    },
    {
      path: ':id/pay',
      component: () => import(/* webpackChunkName: "Pay" */ '../views/Pay.vue'),
      name: 'Pay',
    },
    {
      path: ':id/done',
      component: () => import(/* webpackChunkName: "PaymentDone" */ '../views/PaymentDone.vue'),
      name: 'PaymentDone',
    },
  ],
};
