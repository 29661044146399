export default {

  // App
  APP: 'app',
  SET_MESSAGE: 'SET_MESSAGE',
  SHOW_BUSY: 'SHOW_BUSY',
  HIDE_BUSY: 'HIDE_BUSY',
  SHOW_INFORMATION: 'SHOW_INFORMATION',
  SHOW_SUCCESS: 'SHOW_SUCCESS',
  SHOW_WARNING: 'SHOW_WARNING',
  SHOW_ERROR: 'SHOW_ERROR',
  HIDE_NOTIFICATION: 'HIDE_NOTIFICATION',
  // GET_APP_CONFIG: 'GET_APP_CONFIG',
  // SET_APP_CONFIG: 'SET_APP_CONFIG',
  // SETUP_APP: 'SETUP_APP',
  // NAVIGATE_TO: 'NAVIGATE_TO',
  HANDLE_REQUEST_ERROR: 'HANDLE_REQUEST_ERROR',

  // Invoice
  INVOICE: 'invoice',
  GET_INVOICES: 'GET_INVOICES',
  SET_INVOICES: 'SET_INVOICES',
  GET_ADVANCE_PAYMENTS: 'GET_ADVANCE_PAYMENTS',
  SET_ADVANCE_PAYMENTS: 'SET_ADVANCE_PAYMENTS',

  // Payment
  PAYMENT: 'payment',
  START_PAYMENT: 'START_PAYMENT',
  SAVE_PAYMENT_ATTEMPT: 'SAVE_PAYMENT_ATTEMPT',
  GET_PAYMENT_ATTEMPT: 'GET_PAYMENT_ATTEMPT',
  SET_PAYMENT_ATTEMPT: 'SET_PAYMENT_ATTEMPT',
  MAKE_PAYMENT: 'MAKE_PAYMENT',
  SET_PAYMENT_ERROR: 'SET_PAYMENT_ERROR',
  // SET_PAYMENT_DOCUMENT: 'SET_PAYMENT_DOCUMENT',
  GET_PAYMENT_ATTEMPTS: 'GET_PAYMENT_ATTEMPTS',
  SET_PAYMENT_ATTEMPTS: 'SET_PAYMENT_ATTEMPTS',
  SET_PAYMENT_METHOD: 'SET_PAYMENT_METHOD',
  SET_PAYMENT_DETAIL: 'SET_PAYMENT_DETAIL',
  GET_EXCHANGE_RATE: 'GET_EXCHANGE_RATE',
  SET_EXCHANGE_RATE: 'SET_EXCHANGE_RATE',
  SET_EVALUATION_START: 'SET_EVALUATION_START',
  SET_INVOICE_PAYMENT_SAP: 'SET_INVOICE_PAYMENT_SAP',

  // Credit Card
  CREDIT_CARD: 'creditCard',
  GET_CREDIT_CARDS: 'GET_CREDIT_CARDS',
  SET_CREDIT_CARDS: 'SET_CREDIT_CARDS',
  SET_CREDIT_CARD: 'SET_CREDIT_CARD',
  RESET_CREDIT_CARD: 'RESET_CREDIT_CARD',
  DELETE_CREDIT_CARD: 'DELETE_CREDIT_CARD',
};
