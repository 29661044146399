<template>
  <v-footer app id="footer-stl" padless>
    <v-col
      class="text-center"
      cols="12"
    >
      {{ new Date().getFullYear() }} — <strong>Puntacana International School</strong>
    </v-col>
  </v-footer>
</template>
<script>
export default {
  name: 'Footer',
};
</script>

<style>
#footer-stl{
  background-color: rgba(255,255,255,0.8);
}
</style>
