import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/axios.conf';
import invoice from '../modules/quickPayments/store/invoice';
import payment from '../modules/quickPayments/store/payment';
import creditCard from '../modules/quickPayments/store/creditCard';
// import types from '@/types';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    resp_solcupo: '',
    tutor_principal: '',
    estudiante_vive: '',
    padre_direccion: null,
    DataForm: '',
    solcupo_data_update: false,
  },
  getters: {
    user: (state) => state.user,
    getDataForm: (state) => state.DataForm,
    getSolcupo: (state) => state.solcupo_data_update,
  },
  mutations: {
    user(state, user) {
      state.user = user;
    },
    resp_solcupo(state, resp) {
      state.resp_solcupo = resp;
    },
    tutor_principal(state, resp) {
      state.tutor_principal = resp;
    },
    setDataForm(state, val) {
      state.DataForm = val;
    },
    set_solcupo_data_update(state, data) {
      state.solcupo_data_update = data;
    },
    set_estudiante_vive(state, data) {
      state.estudiante_vive = data;
    },
    set_padre_direccion(state, data) {
      state.padre_direccion = data;
    },

  },
  actions: {
    user(context, user) {
      context.commit('user', user);
    },
    async solcupo_data_update(context, { typeID, id }) {
      try {
        const { data } = await axios.get(`api/formadmission/solcupo?${typeID}=${id}`);
        context.commit('set_solcupo_data_update', data);
      } catch (error) {
        console.log(error);
      }
    },
  },
  modules: {
    invoice,
    payment,
    creditCard,
  },
});
