export default {
  en: {
    Continuar: 'Next',
    Atras: 'Back',
    Enviar: 'Send',
    Centro: 'School',
    'Informaciones Generales - Paso': 'General Information - Step',
    'Generales Padre - Paso': 'Father Information - Step',
    'Generales Madre - Paso': 'Mother Information - Step',
    'Información Estudiante - Paso': 'Students information - Step',
    'Información del desarrollo - Paso': 'Development information - Step',
    'Informaciones Médicas / Otras - Paso': 'Medical information - Step',
    'Año Escolar': 'School Year',
    'Grado Escolar': 'Grade',
    'Tutor Principal': 'Senior Tutor',
    'El estudiante vive con': 'The student lives with',
    'Fecha de Nacimiento': 'Date of birth',
    Madre: 'Mother',
    Padre: 'Father',
    'Madre Divorciada': 'Divorced Mother',
    'Madre Soltera': 'Single Mother',
    'Madre Viuda': 'Widowed Mother',
    'Padre Divorciado': 'Divorced Father',
    'Padre Soltero': 'Single Father',
    'Padre Viudo': 'Widowed Father',
    Otros: 'Others',
    'Ambos padres': 'Both Parents',
    'Egresado del': 'Graduated from',
    Si: 'Yes',
    'Tipo de ID': 'ID Type',
    Cédula: 'National ID',
    Pasaporte: 'Passport',
    'Número de Identificación (ID)': 'Identification Number',
    Nombre: 'Name',
    Apellidos: 'Surname',
    Nacionalidad: 'Nationality',
    Sexo: 'Sex',
    Profesión: 'Profession',
    Celular: 'Mobile Number',
    Dirección: 'Address',
    País: 'Country',
    Ciudad: 'City',
    Población: 'Locality',
    Calle: 'Street',
    Colegio: 'Current School',
    Idioma: 'Language',
    Teléfono: 'Phone',
    'Lugar de trabajo': 'Place of Employment',
    'Teléfono del trabajo': 'Work Phone',
    'Teléfono casa': 'Home Phone',
    'Otra Población': 'Other Locality',
    'Documento de Identificación': 'Identification Document',
    'Lugar de nacimiento': 'Place of birth',
    'Lengua nativa': 'Spoken Languages',
    'Otro idioma': 'Other Languages',
    'Nombre del Colegio': 'School',
    'Fecha entrada': 'Entry Date',
    'Fecha salida': 'Exit Date',
    'DIA/MES/AÑO': 'DAY/MONTH/YEAR',
    'Hermanos del estudiante': "Student's siblings",
    'Tiene hermano(s)': 'His son has siblings',
    'Ha estado su hijo(a) actualmente incristo en un': 'Has your child currently been enrolled',
    'centro educativo?': 'in school?',
    'Record de Notas': 'Record of Notes',
    'Carta de Saldo': 'Balance Letter',
    Embarazo: 'Pregnancy',
    Planificado: 'Planned',
    'No planificado': 'Not Planned',
    Abortos: 'Abortions',
    Peso: 'Weight',
    Lloró: 'Cried',
    Tiempo: 'Time',
    Gateó: 'Crawl',
    Arrastró: 'Dragging',
    Caminó: 'Walked',
    Orina: 'Urine',
    Alergias: 'Allergies',
    'Heces Fecales': 'Feces',
    'Inicio del lenguaje': 'Language acquisition',
    'Leche maternal': 'Breast feed',
    'Control de esfínter': 'Sphincter control',
    'Hábitos para comer': 'Eating habits',
    'Hábitos para dormir': 'Sleeping habits',
    'Visitando un especialista?': 'Seeing any especiallist?',
    'Actividades que le disgustan': "Activities he/she doesn't like",
    'Relación con sus padres': 'Relationship with parents',
    'Narre algua situación que haya marcado la vida escolar de su hijo (a)': 'School situations that have marked the life of your child',
    'Su hijo ha asistido a algún terapeuta / profesional de la salud mental ( psicólogo, psiquiatra ) ?': 'Have your child visitad any therapist/mental health specialist ?',
    'Por favor, especificar el motivo y si aún continúa asistiendo': 'please specify the motive and if still attending',
    '¿Permite al PCIS contactar al terapeuta o especialista de su hijo(a) ?': 'Would you allow the PCIS to contact the therapist/specialist assisting your child?',
    'Por favor, provea la información de contacto': 'Please provide contact information',
    'Condiciones especiales de salud (cardiaca, audición, asma, diabetes, visual, etc.)': 'Special Health conditions (cardiac, hearing, asthma, diabetes, visual, etc.)',
    '¿Se encuentra el estudiante bajo algún tratamiento médico?': 'Is your child currently taking any medications?',
    'Enfermedades anteriores, favor de especificar': 'Previous diseases, please specify',
    'Razones por las cuales decidió aplicar a Puntacana International School': 'Reason for applying to Puntacana lnternational School',
    'Su hijo(a) ha repetido algún grado escolar?': 'Has your child repeated a grade?',
    'Favor explicar': 'please explain',
    '¿Su hijo(a) ha suspendido(a) o expulsado de otro colegio por razones de conducta o académica?': 'Has your child been suspended or expelled from another School for academic reasons or misconduct?',
    'La personalidad de su hijo(a) y sus relaciones con otros niños y/o hermanos': "Your child's personality and interactions with other children and/or with siblings",
    Personalidad: 'Personality',
    'Características que más admira de su hijo(a)': 'Characteristics you most admire in your child',
    Características: 'Characteristics',
    'Indique las actividades extracurriculares (programadas fuera del colegio) en la que participe su hijo(a)': "Your child's structured activities outside school",
    Actividades: 'Activities',
    'La actitud de su hijo(a) hacia la escuela y aprendizaje en general': "Your child's attitude towards school and/or learning.",
    Actitud: 'Attitude',
    'La conducta de su hijo(a)<br> en la casa': "Your child's behavior<br> at home",
    Conducta: 'Behavior',
    '¿Desea compartir con nosotros alguna información adicional acerca de su hijo(a)?': "What more would you like your child's teacher know about your child?",
    'Información adicional': 'Additional information',
    'En caso de emergencia llamar a': 'In case of an emergency call',
    Parentesco: 'Relationship',
    'Grupo de sangre': 'Blood Type',
    'Antecedentes Ginecológico – Obstetricos': 'Background gynecology - obstetrics',
    'Semanas de embarazo': 'Weeks of pregnancy to the arrival of their child',
    'Vía de nacimiento': 'Way of Birth',
    CESAREA: 'CESAREAN',
    PARTO: 'Childbirth',
    'Cantidad de hijos': 'Number of children',
    'Número al que corresponde': 'Number corresponding to the child',
    'Se le administro antibiótico al nacer?': 'Have you given antibiotics at birth?',
    Especificar: 'Please, specify',
    'Usa su hijo anteojos?': 'Does he/she wear glasses?',
    'Enfermedades propias de la infancia': 'Childhood diseases',
    Varicela: 'Chickenpox',
    Sarampión: 'Measles',
    Papera: 'Mumps',
    Rubeola: 'German Measles',
    Ninguna: 'None',
    Enfermedades: 'Diseases',
    'El/la niño/a ha padecido alguna de estas enfermedades?': '¿Has the child had any of these diseases?',
    '¿Tiene completo el esquema de vacunación correspondiente a su edad?': 'Did you complete the immunization schedule for their age?',
    '¿Su hijo(a) tiene la vacuna del COVID?': 'Does your child has the COVID Vaccine?',
    'Antecedentes alérgicos': 'Allergic background',
    '¿Ha hecho alergia a algún medicamento?': 'Has allergies to any medications?',
    Aspirina: 'Aspirin',
    'N-Butil': 'N-Butyl',
    Penicilina: 'Penicillin',
    Ampicilina: 'Ampicillin',
    Ibuprofeno: 'lbuprofen',
    Dipirona: 'Dipyrone',
    Diclofenal: 'Diclofenac',
    Otro: 'Other',
    'Especificar Otro': 'Specify another',
    '¿Su hijo/a ha sufrido algún accidente importante?': 'Has he/she had any accident of importance?',
    '¿Ha tenido accidentes de hiperactividad, desórdenes mentales o procesos que hayan ameritado un tratamiento psiquiátrico?': 'Has he/she had any hyperactivity accidents, mental disorders or processes that merited psychiatric treatment?',
    '¿Su hijo/a utiliza o ha utilizado algún medicamento controlado?': 'Is your child using or used any controlled medication?',
    '¿Alguien en la familia ha hecho algun tipo de alergia?': 'Does anyone in the family has any allergies?',
    'Antecedentes Quirúrgicos': 'Surgical background',
    '¿Alguna vez ha sido operado?': 'Has he/she been on surgery before?',
    '¿Ha sido trasfundido alguna vez?': '¿Has been transfused?',
    '¿Padece de alguna de estas enfermedades?': 'Does he/she suffer from any of these diseases? ',
    Asma: 'Asthma',
    Falcemia: 'Falcaemia',
    Leucemia: 'Leukemia',
    Epilepsia: 'Epilepsy',
    Amigdalitis: 'Tonsillitis',
    'Autorizo al PCIS en caso de emergencia a transportar a mi hijo/a al centro médico que consideren en caso de no ser contactado/a.': 'I authorize PCIS to transport my son/daughter to a medical center in case of an emergency and if I cannot be contactad.',
    'Autorizo al PCIS de aplicar a mi hijo/a el tipo de medicación necesaria en caso de alguna emergencia.': ' I authorize PCIS to treat my son/daughter with the appropriate medication needed in case of an emergency.',
    'Con la firma del presente documento declaro que la información que he suministrado al PCIS es fidedigna y autorizo al PCIS a verificar las mismas.': 'By signing this document, 1 declare that the information I have provided to PCIS is reliable and I authorize PCIS to verify the same.',
    'Favor mencione cualquier información u observación que considere relevante para poder satisfacer las necesidades académicas y emocionales de su hijo(a) (divorcios, pérdidas de seres queridos, mudanzas, dificultades académicas, etc.)': 'Please provide any information you consider to be important in order to satisfy the academic and emotional needs of your child (i.e. divorces, death of close relatives, current relocations, academic difficulties, etc.)',
    Observación: 'any information',
    'El Centro es obligatorio.': 'School is required.',
    'El archivo debe ser inferior a 2 MB.': 'The file must be less than 2 MB.',
    'Archivo no valido formatos permitidos': 'Invalid file allowed formats',
    'Faltan Campos': 'Fields missing',
    Mensaje: 'Message',
    Cerrar: 'Close',
    login: {
      title: 'Iniciar sesión - Admisiones',
      form: {
        password: 'Contraseña',
        fgotpassword: 'Olvidé mi contraseña',
      },
      validateFrm: {
        email: 'E-mail es obligatorio.',
        required: 'This field is required.',
        email_valid: 'El correo electrónico debe ser válido',
        min: 'Mínimo 8 caracteres',
      },
    },
  },
  es: {
    register: {
      title: 'Registro - Admisiones',
      form: {
        name: 'Nombre',
        lastname: 'Apellidos',
        password: 'Contraseña',
        repassword: 'Confirmar contraseña',
        register: 'Registrar',
        btnLogin: 'Regresar al login',
      },
      validateFrm: {
        email: 'E-mail es obligatorio.',
        required: 'Este campo es obligatorio.',
        email_valid: 'El correo electrónico debe ser válido',
        min: 'Mínimo 8 caracteres',
        password_match: 'La contraseña debe coincidir',
      },
    },
    login: {
      title: 'Iniciar sesión - Admisiones',
      form: {
        password: 'Contraseña',
        fgotpassword: 'Olvidé mi contraseña',
      },
      validateFrm: {
        email: 'E-mail es obligatorio.',
        required: 'Este campo es obligatorio.',
        email_valid: 'El correo electrónico debe ser válido',
        min: 'Mínimo 8 caracteres',
      },
    },
    forgotPassword: {
      title: 'Olvidé mi contraseña',
      btnSend: 'Enviar',
    },
    user: {
      mail: 'Bienvenido',
    },
    general: {
      register: 'Registrarse',
      logout: 'Salir',
    },
    admission: {
      rulesPhone: 'Telefono casa o celular al menos 1 es requerido.',
      maxGPCode: 'Máximo 8 caracteres',
    },
  },
};
