import axios from 'axios';

import { convertExpirationToAzul } from '@/utils/payment';

const resourse = 'api/v1/evaluation-pcis';
const applicationId = process.env.VUE_APP_APPLICATION_ID;

class PaymentService {
   savePaymentAttempt = async (paymentData) => {
     const { invoices, ...data } = paymentData;
     const config = {
       method: 'post',
       url: `${process.env.VUE_APP_BLACKBOX_URL}/${resourse}/payment-attempt/`,
       headers: {
         Application: `Bifrost ${applicationId}`,
         'Content-Type': 'application/json',
       },
       data,
     };

     const response = await axios(config);
     return response;
   }

    // Estructura para el Advance Payment de la evaluacion
    evaluationPaymentStart = async (merchantNumber) => {
      const config = {
        method: 'get',
        url: `${process.env.VUE_APP_BLACKBOX_URL}/${resourse}/client/ESPORADICO/advance-payment?merchant=${merchantNumber}`,
        headers: {
          Application: `Bifrost ${applicationId}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios(config);
      return response;
    }

    getPaymentAttempt = async (paymentId) => {
      const config = {
        method: 'get',
        url: `${process.env.VUE_APP_BLACKBOX_URL}/${resourse}/payment-attempt/${paymentId}/`,
        headers: {
          Application: `Bifrost ${applicationId}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios(config);
      return response;
    }

    makePayment = async (paymentId, creditCard) => {
      let card;
      if (creditCard.id === 0) {
        card = {
          card: { ...creditCard },
        };
        delete card.card.id;
        card.card.number = card.card.number.replace(/ /g, '');
        card.card.expiration = convertExpirationToAzul(card.card.expiration);
      } else {
        card = { card_uuid: creditCard.id };
      }

      const config = {
        method: 'post',
        url: `${process.env.VUE_APP_BLACKBOX_URL}/${resourse}/payment-attempt/${paymentId}/charge/`,
        headers: {
          Application: `Bifrost ${applicationId}`,
          'Content-Type': 'application/json',
        },
        data: card,
      };

      const response = await axios(config);
      return response;
    }
}

export default PaymentService;
