<template>
<div class="bg-login">
  <v-container>
    <v-card
      class="d-flex align-center justify-center overflow-hidden"
      color="transparent"
      flat
      height="80vh"
      tile
      outlined
    >
      <v-card class="px-8 py-6" outlined tile width="360">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title>{{ $t('forgotPassword.title') }}</v-card-title>
          <v-text-field
            outlined
            v-model="email"
            :rules="emailRules"
            name="email"
            label="E-mail"
            required
          ></v-text-field>
         <v-card-actions class="d-flex justify-space-between pa-0">
            <v-btn color="info" to="/"> {{ $t('register.form.btnLogin') }}</v-btn>
            <v-btn :disabled="!valid" color="success" @click="validate">
              {{ $t('forgotPassword.btnSend') }}
            </v-btn>
          </v-card-actions>
          <br/>
          <v-alert
            dense
            dismissible
            outlined
            text
            v-model="errorAPI"
            type="error"
          >
          {{ errorMSG }}
          </v-alert>
        </v-form>
      </v-card>
    </v-card>
  </v-container>
</div>
</template>

<script>
import axios from 'axios';

import i18n from '@/plugins/i18n';

export default {
  name: 'Forgot',
  data: () => ({
    errorAPI: false,
    errorMSG: '',
    valid: true,
    show: false,
    email: '',
    emailRules: [
      (v) => !!v || i18n.t('login.validateFrm.email'),
      (v) => /.+@.+\..+/.test(v) || i18n.t('login.validateFrm.email_valid'),
    ],
  }),

  methods: {
    validate() {
      const vald = this.$refs.form.validate();
      if (vald) {
        this.login();
      }
    },
    async login() {
      const loginData = JSON.stringify({
        email: this.email,
        password: this.password,
      });
      const config = {
        method: 'post',
        url: 'http://localhost:4000/api/auth/login',
        headers: {
          'Content-Type': 'application/json',
        },
        data: loginData,
      };

      try {
        const response = await axios(config);

        this.$store.dispatch('user', response.data.token);
        this.$router.push('/register');

        // console.log(response);
      } catch (error) {
        this.errorMSG = error.response.data.msg;
        this.errorAPI = true;
        // console.log(error.response.data);
      }
    },
  },
};
</script>
<style>
.bg-login {
  background: url('/assets/img/bg-general.jpg')
    no-repeat top center fixed !important;
  background-size: cover !important;
  height: 100%;
}
.bg-login:before {
  content: "";
  position: absolute;
  left: 0; right: 0;
  top: 0; bottom: 0;
  background: rgba(0,0,0,.3);
}
</style>
