import types from '@/types';
import InvoiceService from '../services/InvoiceService';

const data = {
  invoices: [],
  advancePayments: [],
  customerName: null,
  notification: {
    message: '',
    color: 'info',
    show: false,
  },
  busy: {
    message: '',
    show: false,
  },
};

const getters = {
  noInvoices: (state) => state.invoices.length === 0,
  selectedInvoices: (state) => state.invoices.filter((item) => item.checked),
  total: (state) => state.invoices.reduce((a, b) => a + parseFloat(b.MONTO_DOP), 0),
  exchangeRate: (state) => state.invoices.map((item) => item.TASA)[0],
};

const mutations = {
  [types.SET_INVOICES](state, invoices = []) {
    state.invoices = invoices;
    const nombre = invoices.map((item) => item.NOMBRE_CLIENTE)[0];
    state.customerName = nombre;
    this.state.payment.payment.sap_customer_name = nombre;
  },
  [types.SET_ADVANCE_PAYMENTS](state, advancePayments) {
    state.advancePayments = advancePayments;
  },
  [types.SHOW_INFORMATION](state, message) {
    state.notification.message = message;
    state.notification.color = 'info';
    state.notification.show = true;
  },
  [types.SHOW_SUCCESS](state, message) {
    state.notification.message = message;
    state.notification.color = 'success';
    state.notification.show = true;
  },
  [types.SHOW_WARNING](state, message) {
    state.notification.message = message;
    state.notification.color = 'warning';
    state.notification.show = true;
  },
  [types.SHOW_ERROR](state, message) {
    state.notification.message = message;
    state.notification.color = 'error';
    state.notification.show = true;
  },
  [types.HIDE_NOTIFICATION](state) {
    state.notification.show = false;
  },
  [types.SHOW_BUSY](state, message) {
    state.busy.message = message;
    state.busy.show = true;
  },
  [types.HIDE_BUSY](state) {
    state.busy.show = false;
  },
};

const actions = {
  [types.GET_INVOICES]({ commit, dispatch }, { params }) {
    const invoiceService = new InvoiceService();
    return invoiceService.getPaymentDetail(params)
      .then((response) => {
        const invoices = response.data;
        commit(types.SET_INVOICES, invoices);
      })
      .catch((error) => {
        dispatch(types.HANDLE_REQUEST_ERROR, { error }, { root: true });
      });
  },
//   [types.GET_ADVANCE_PAYMENTS]({ commit, dispatch }, { sapCustomer, merchant }) {
//     const invoiceService = new InvoiceService();
//     return invoiceService.getAdvancePayments(sapCustomer, merchant)
//       .then((response) => {
//         const advancePayments = response.data.map((item) => ({
//           ...item,
//           amount: 0,
//           currency: 'dop',
//           position: 10,
//         }));
//         commit(types.SET_ADVANCE_PAYMENTS, advancePayments);
//       })
//       .catch((error) => {
//         dispatch(types.HANDLE_REQUEST_ERROR, { error }, { root: true });
//       });
//   },
};

export default {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};
