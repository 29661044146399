<template>
  <v-app>
    <v-app-bar
      app
      color="brand-primary"
      height="100"
    >
      <div class="d-flex align-center">
        <router-link to="/">
        <v-img
        alt="Punta Cana International School"
        class="shrink mr-2"
        contain
        max-height="260"
        max-width="260"
        src="/assets/img/logo-puntacana-school.png"/>
        </router-link>
      </div>

      <v-spacer></v-spacer>
         <!-- <div>
    <v-select
      v-model="$i18n.locale"
      :items="locales"
      item-text="name"
      item-value="val"
      dense
    >
    </v-select>
  </div> -->
  <div>
    <button v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)"
    v-show="$i18n.locale!==entry.language">
        <flag :iso="entry.flag" v-bind:squared=false /> {{entry.title}}
    </button>
</div>
  <!-- <div class="locale-changer">
    <select v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
    </select>
  </div> -->
        <v-btn
          text
          @click="btnRegister"
          v-if="!user"
        >
          <span class="mr-2">{{ $t('general.register') }}</span>
          <v-icon>mdi-account-plus</v-icon>
        </v-btn>
        <v-btn
          text
          @click="logout"
          v-if="user"
        >
          <span class="mr-2">{{ $t('general.logout') }}</span>
          <v-icon>mdi-logout</v-icon>
        </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
      <Footer />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

import i18n from '@/plugins/i18n';
import Footer from '../partials/Footer.vue';

export default {
  name: 'PublicLayout',
  components: {
    Footer,
  },
  data() {
    return {
      languages: [
        { flag: 'us', language: 'en', title: 'English' },
        { flag: 'es', language: 'es', title: 'Español' },
      ],
    };
  },
  methods: {
    changeLocale(locale) {
      i18n.locale = locale;
    },
    logout() {
      this.$store.dispatch('user', null);
      this.$router.push('/');
    },
    btnRegister() {
      this.$router.push('/register');
    },
  },
  computed: {
    ...mapGetters(['user']),
  },
};
</script>
<style>
/* body .v-application .brand-primary {
    background-color: rgba(255,255,255,0.8) !important;
} */
</style>
