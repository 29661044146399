// Utility to handle some azul stuffs.

export const paymentIsDeclined = (isoCode) => {
  if (!isoCode) {
    return false;
  }
  return isoCode !== '00';
};

export const getResponseDescription = (error) => {
  const message = `${error.IsoCode} - ${error.ResponseMessage}`;
  return message;
};

export const getErrorByErrorDescription = (errorDescription) => {
  const message = `${errorDescription}`;
  return message;
};

export const convertExpirationToAzul = (value) => {
  /* Azul expect the expiration date in the form of
    202012 and the collectec one in form is 12 / 20
  */
  const expiration = value.replace(/ /g, '');
  const month = expiration.split('/')[0];
  const year = expiration.split('/')[1];
  return `20${year}${month}`;
};

export const parseCreditCard = (data) => {
  const expiration = convertExpirationToAzul(data.expiration);
  const number = data.number.replace(/ /g, '');
  const creditCard = {
    ...data,
    number,
    expiration,
  };
  return creditCard;
};

export default {
  paymentIsDeclined,
  getResponseDescription,
  getErrorByErrorDescription,
  convertExpirationToAzul,
};
