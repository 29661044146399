import Vue from 'vue';
import VueRouter from 'vue-router';
import PublicLayout from '@/views/layout/Public.vue';
import AppLayout from '@/views/layout/App.vue';
import Login from '../components/login/login.vue';
import Register from '../components/login/Register.vue';
import Forgot from '../components/login/ForgotPassword.vue';
import Dashboard from '../components/dashboard/Dashboard.vue';
import Admission from '../components/Admission/Admission.vue';
import Books from '../components/books/books.vue';
import AdmissionDone from '../views/AdmissionDone.vue';
import quickPaymentsRouter from '../modules/quickPayments/router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    component: PublicLayout,
    children: [
      {
        path: '',
        component: Login,
      },
    ],
  },
  {
    path: '/register',
    component: PublicLayout,
    children: [
      {
        path: '',
        component: Register,
      },
    ],
  },
  {
    path: '/forgot',
    component: PublicLayout,
    children: [
      {
        path: '',
        component: Forgot,
      },
    ],
  },
  {
    path: '/dashboard',
    component: AppLayout,
    children: [
      {
        path: '',
        component: Dashboard,
      },
    ],
  },
  {
    path: '/',
    component: AppLayout,
    children: [
      {
        path: '',
        component: Admission,
        name: 'home',
      },
      {
        path: 'libros',
        component: Books,
        name: 'libros',
      },
      {
        path: ':id',
        component: Admission,
      },
      {
        path: 'finish',
        component: AdmissionDone,
        name: 'finish',
      },
    ],
  },
  quickPaymentsRouter,
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
