import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { en, es } from 'vuetify/es5/locale';

Vue.use(Vuetify);

const lang = window.localStorage.currentLanguage ? window.localStorage.currentLanguage : 'es';

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#003087',
        secondary: '#424242',
        tertiary: '#00a8e6',
      },
    },
  },
  lang: {
    locales: { en, es },
    current: lang,
  },
};

export default new Vuetify(opts);
