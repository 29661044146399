<!-- eslint-disable max-len -->
<template>
<v-form ref="frmTutor">
        <v-container fluid>
        <v-row>
<v-col>
<v-text-field
    v-model="infomedica.salud"
    :label="$t(`Condiciones especiales de salud (cardiaca, audición, asma, diabetes, visual, etc.)`)
    +':'"
    outlined
    :rules="[rules.required]"
    v-uppercase
></v-text-field>
</v-col>
</v-row>
        <v-row>
            <v-col>
                <v-text-field
                    v-model="infomedica.tratamiento_medico"
                    :label="$t('¿Se encuentra el estudiante bajo algún tratamiento médico?')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
          </v-row>
        <v-row>
            <v-col>
                <v-text-field
                    v-model="infomedica.enfermedades_anteriores"
                    :label="$t('Enfermedades anteriores, favor de especificar')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
      <v-col>
          <v-text-field
              v-model="infomedica.razonesPCIS"
              :label="$t('Razones por las cuales decidió aplicar a Puntacana International School')
              +':'"
              outlined
              :rules="[rules.required]"
              v-uppercase
          ></v-text-field>
      </v-col>
        </v-row>
        <v-row>
            <v-col cols="12"  md="4">
                {{$t('Su hijo(a) ha repetido algún grado escolar?')}}
                <v-radio-group
                v-model="infomedica.repetido_annio"
                row
                :rules="[rules.required]"
                >
                <v-radio
                    :label="$t('Si')"
                    value="si"
                ></v-radio>
                <v-radio
                    label="No"
                    value="no"
                ></v-radio>
                </v-radio-group>
                <v-text-field
                v-if="infomedica.repetido_annio === 'si'"
                    v-model="infomedica.repetido_annio_explicar"
                    :label="$t('Favor explicar')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
            <v-col cols="12"  md="8">
                {{$t(`¿Su hijo(a) ha suspendido(a) o expulsado de otro colegio por
                razones de conducta o académica?`)}} &nbsp;
                <v-radio-group
                v-model="infomedica.expulsado"
                row
                :rules="[rules.required]"
                >
                <v-radio
                    :label="$t('Si')"
                    value="si"
                ></v-radio>
                <v-radio
                    label="No"
                    value="no"
                ></v-radio>
                </v-radio-group>
                <v-text-field
                v-if="infomedica.expulsado === 'si'"
                    v-model="infomedica.expulsado_explicar"
                    :label="$t('Favor explicar')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
        <v-col cols="4">
            {{$t('La personalidad de su hijo(a) y sus relaciones con otros niños y/o hermanos')}}:
            <v-textarea
            v-model="infomedica.personalidad"
            outlined
            :rules="[rules.required]"
            auto-grow
            row-height="25"
            rows="3"
            :label="$t('Personalidad')+':'"
            v-uppercase
            ></v-textarea>
        </v-col>
            <v-col cols="3">
                {{$t('Características que más admira de su hijo(a)')}}:
                <v-textarea
                v-model="infomedica.caracteristicas_admira"
                outlined
                :rules="[rules.required]"
                auto-grow
                row-height="25"
                rows="3"
                :label="$t('Características')+':'"
                v-uppercase
                ></v-textarea>
            </v-col>
            <v-col cols="5">
                {{$t(`Indique las actividades extracurriculares (programadas
                    fuera del colegio) en la que participe su hijo(a)`)}}
                <v-textarea
                v-model="infomedica.actividades"
                outlined
                :rules="[rules.required]"
                auto-grow
                row-height="25"
                rows="3"
                :label="$t('Actividades')+':'"
                v-uppercase
                ></v-textarea>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                {{$t('La actitud de su hijo(a) hacia la escuela y aprendizaje en general')}}:
                <v-textarea
                v-model="infomedica.actitud_aprendizaje"
                outlined
                :rules="[rules.required]"
                auto-grow
                row-height="25"
                rows="3"
                :label="$t('Actitud')+':'"
                v-uppercase
                ></v-textarea>
            </v-col>
            <v-col cols="4">
                <span v-html="$t('La conducta de su hijo(a)<br> en la casa')+':'"></span>
                <v-textarea
                v-model="infomedica.conducta"
                outlined
                :rules="[rules.required]"
                auto-grow
                row-height="25"
                rows="3"
                :label="$t('Conducta')+':'"
                v-uppercase
                ></v-textarea>
            </v-col>
      <v-col cols="4">
          {{$t('¿Desea compartir con nosotros alguna información adicional acerca de su hijo(a)?')}}
          <v-textarea
          v-model="infomedica.info_adicional"
          outlined
          :rules="[rules.required]"
          auto-grow
          row-height="25"
          rows="3"
          :label="$t('Información adicional')+':'"
          v-uppercase
          ></v-textarea>
      </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card-title class="text-h5 blue lighten-5">
                    {{$t('En caso de emergencia llamar a')}}:
                </v-card-title>
            </v-col>
        </v-row>
        <v-row v-for="(input,k) in inputEmergencia.slice(0,3)" :key="k">
            <v-col cols="4">
                <v-text-field
                    :prepend-icon="(k || (
                         !k && inputEmergencia.length > 1))? 'mdi-minus-circle':''"
                    @click:prepend="removeEmergencia(k)"
                    v-model="input.nombre"
                    :label="$t('Nombre')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-text-field
                    v-model="input.parentesco"
                    :label="$t('Parentesco')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-text-field
                    :append-outer-icon="k == inputEmergencia.length-1 ? 'mdi-plus-circle':''"
                    @click:append-outer="addEmergencia"
                    v-model="input.telefono"
                    :label="$t('Teléfono')+':'"
                    outlined
                    v-mask="'(###)-###-####'"
                    :rules="[rules.required]"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card-title class="text-h5 blue lighten-5">
                    Pediatra:
                </v-card-title>
            </v-col>
        </v-row>
         <v-row>
            <v-col cols="4">
                <v-text-field
                    v-model="infomedica.pediatra_nombre"
                    :label="$t('Nombre')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-text-field
                    v-model="infomedica.pediatra_telefono"
                    :label="$t('Teléfono')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-mask="'(###)-###-####'"
                ></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-autocomplete
                    v-model="infomedica.grupo_sangre"
                    :items="sangreOptions"
                    :label="$t('Grupo de sangre')+':'"
                    outlined
                    :rules="[rules.required]"
                    ></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <v-text-field
                    v-model="infomedica.info_medadional"
                    :label="$t('Información adicional')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card-title class="text-h5 blue lighten-5">
                    {{$t('Antecedentes Ginecológico – Obstetricos')}}
                </v-card-title>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                <v-text-field
                    v-model="infomedica.semana_embarazo"
                    :label="$t('Semanas de embarazo')+':'"
                    type="number"
                    outlined
                    :rules="[rules.required]"
                ></v-text-field>
            </v-col>
            <v-col cols="3">
                <v-select
                    v-model="infomedica.via_nacimiento"
                    :items="[
                    { text: $t('CESAREA'), value: 'cesarea'},
                    { text: $t('PARTO'), value: 'parto' },
                    ]"
                    :label="$t('Vía de nacimiento')+':'"
                    :rules="[rules.required]"
                    outlined
                    ></v-select>
            </v-col>
            <v-col cols="3">
                <v-text-field
                    v-model="infomedica.cantidad_hijos"
                    :label="$t('Cantidad de hijos')+':'"
                    type="number"
                    outlined
                    :rules="[rules.required]"
                ></v-text-field>
            </v-col>
            <v-col cols="3">
                <v-text-field
                    v-model="infomedica.numero_corresponde"
                    :label="$t('Número al que corresponde')+':'"
                    outlined
                    type="number"
                    :rules="[rules.required]"
                ></v-text-field>
            </v-col>
        </v-row>
         <v-row>
            <v-col>
                {{$t('Se le administro antibiótico al nacer?')}}
                <v-radio-group
                v-model="infomedica.antibiotico"
                row
                :rules="[rules.required]"
                >
                <v-radio
                    :label="$t('Si')"
                    value="si"
                ></v-radio>
                <v-radio
                    label="No"
                    value="no"
                ></v-radio>
                </v-radio-group>
                <v-text-field
                v-if="infomedica.antibiotico === 'si'"
                    v-model="infomedica.antibiotico_especificar"
                    :label="$t('Especificar')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
            <!-- <v-col>
                Actualmente, tiene algún tratamiento médico?
                <v-radio-group
                v-model="infomedica.tratamiento_medico"
                row
                :rules="[rules.required]"
                >
                <v-radio
                    :label="$t('Si')"
                    value="si"
                ></v-radio>
                <v-radio
                    label="No"
                    value="no"
                ></v-radio>
                </v-radio-group>
            </v-col> -->
            <v-col>
                {{$t('Usa su hijo anteojos?')}}
                <v-radio-group
                v-model="infomedica.anteojos"
                row
                :rules="[rules.required]"
                >
                <v-radio
                    :label="$t('Si')"
                    value="si"
                ></v-radio>
                <v-radio
                    label="No"
                    value="no"
                ></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card-title class="text-h5 blue lighten-5">
                    {{$t('Enfermedades propias de la infancia')}}
                </v-card-title>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                    <v-select
                        v-model="infomedica.enfermedades"
                        attach
                        chips
                        multiple
                        :items="[
                        { text: $t('Varicela'), value: 'varicela'},
                        { text: $t('Sarampión'), value: 'sarampion' },
                        { text: $t('Papera'), value: 'papera' },
                        { text: $t('Rubeola'), value: 'rubeola' },
                        { text: 'COVID', value: 'COVID' },
                        { text: $t('Ninguna'), value: 'ninguna' },
                        ]"
                        :label="$t('Enfermedades')+':'"
                        :hint="$t('El/la niño/a ha padecido alguna de estas enfermedades?')"
                        persistent-hint
                        outlined
                        :rules="[rules.requiredMulti]"
                        ></v-select>
            </v-col>
            <v-col>
                <v-radio-group
                v-model="infomedica.esquema_vacunacion"
                row
                :rules="[rules.required]"
                >
                {{$t('¿Tiene completo el esquema de vacunación correspondiente a su edad?')}}
                <v-radio
                    :label="$t('Si')"
                    value="si"
                ></v-radio>
                <v-radio
                    label="No"
                    value="no"
                ></v-radio>
                </v-radio-group>
            </v-col>
            <v-col>
                <v-radio-group
                v-model="infomedica.vacuna_covid"
                row
                :rules="[rules.required]"
                >
                {{$t('¿Su hijo(a) tiene la vacuna del COVID?')}}
                <v-radio
                    :label="$t('Si')"
                    value="si"
                ></v-radio>
                <v-radio
                    label="No"
                    value="no"
                ></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card-title class="text-h5 blue lighten-5">
                    {{$t('Antecedentes alérgicos')}}
                </v-card-title>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                {{$t('¿Ha hecho alergia a algún medicamento?')}}<br/>
                <v-radio-group
                v-model="infomedica.alergia_medicamento"
                row
                :rules="[rules.required]"
                >
                <v-radio
                    :label="$t('Si')"
                    value="si"
                ></v-radio>
                <v-radio
                    label="No"
                    value="no"
                ></v-radio>
                </v-radio-group>
                <v-select
                        v-if="infomedica.alergia_medicamento === 'si'"
                        v-model="infomedica.alergia_medicamento_multi"
                        attach
                        chips
                        multiple
                        :items="[
                        { text: $t('Aspirina'), value: 'aspirina'},
                        { text: $t('N-Butil'), value: 'n-Butil' },
                        { text: $t('Penicilina'), value: 'penicilina' },
                        { text: $t('Ampicilina'), value: 'ampicilina' },
                        { text: $t('Ibuprofeno'), value: 'Ibuprofeno' },
                        { text: $t('Criopina'), value: 'criopina' },
                        { text: $t('Dipirona'), value: 'dipirona' },
                        { text: $t('Diclofenal'), value: 'diclofenal' },
                        { text: $t('Otro'), value: 'otro' },
                        ]"
                        :label="$t('Especificar')+':'"
                        outlined
                        :rules="[rules.requiredMulti]"
                        ></v-select>
                        <v-text-field
                          v-if="infomedica.alergia_medicamento_multi.includes('otro')"
                          v-model="infomedica.alergia_medicamento_multi_otro"
                          :label="$t('Especificar Otro')+':'"
                          outlined
                          :rules="[rules.required]"
                          v-uppercase
                      ></v-text-field>
            </v-col>
            <v-col cols="6">
                {{$t('¿Su hijo/a ha sufrido algún accidente importante?')}}<br/>
                <v-radio-group
                v-model="infomedica.accidente_importante"
                row
                :rules="[rules.required]"
                >
                <v-radio
                    :label="$t('Si')"
                    value="si"
                ></v-radio>
                <v-radio
                    label="No"
                    value="no"
                ></v-radio>
                </v-radio-group>
                <v-text-field
                v-if="infomedica.accidente_importante === 'si'"
                    v-model="infomedica.accidente_importanteEspef"
                    :label="$t('Especificar')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
            </v-row>
        <v-row>
            <v-col cols="4">
                {{$t(`¿Ha tenido accidentes de hiperactividad,
                desórdenes mentales o procesos que hayan ameritado
                un tratamiento psiquiátrico?`)}}
                <v-radio-group
                v-model="infomedica.accidentes_tratamiento"
                row
                :rules="[rules.required]"
                >
                <v-radio
                    :label="$t('Si')"
                    value="si"
                ></v-radio>
                <v-radio
                    label="No"
                    value="no"
                ></v-radio>
                </v-radio-group>
                <v-text-field
                v-if="infomedica.accidentes_tratamiento === 'si'"
                    v-model="infomedica.accidentes_tratamientoEspef"
                    :label="$t('Especificar')+':'"
                    outlined
                    :rules="[rules.required]"
                ></v-text-field>
            </v-col>
            <v-col cols="4">
                {{$t('¿Su hijo/a utiliza o ha utilizado algún medicamento controlado?')}}
                <v-radio-group
                v-model="infomedica.medicamento_controlado"
                row
                :rules="[rules.required]"
                >
                <v-radio
                    :label="$t('Si')"
                    value="si"
                ></v-radio>
                <v-radio
                    label="No"
                    value="no"
                ></v-radio>
                </v-radio-group>
                <v-text-field
                    v-if="infomedica.medicamento_controlado === 'si'"
                    v-model="infomedica.medicamento_controladoEspef"
                    :label="$t('Especificar')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
            <v-col cols="4">
                {{$t('¿Alguien en la familia ha hecho algun tipo de alergia?')}}<br/>
                <v-radio-group
                v-model="infomedica.alergia_familia"
                row
                :rules="[rules.required]"
                >
                <v-radio
                    :label="$t('Si')"
                    value="si"
                ></v-radio>
                <v-radio
                    label="No"
                    value="no"
                ></v-radio>
                </v-radio-group>
                <v-text-field
                    v-if="infomedica.alergia_familia === 'si'"
                    v-model="infomedica.alergia_familiaEspef"
                    :label="$t('Especificar')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card-title class="text-h5 blue lighten-5">
                    {{$t('Antecedentes Quirúrgicos')}}
                </v-card-title>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                {{$t('¿Alguna vez ha sido operado?')}}<br/>
                <v-radio-group
                v-model="infomedica.operado"
                row
                :rules="[rules.required]"
                >
                <v-radio
                    :label="$t('Si')"
                    value="si"
                ></v-radio>
                <v-radio
                    label="No"
                    value="no"
                ></v-radio>
                </v-radio-group>
                <v-text-field
                    v-if="infomedica.operado === 'si'"
                    v-model="infomedica.operadoEspef"
                    :label="$t('Especificar')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
            <v-col>
                {{$t('¿Ha sido trasfundido alguna vez?')}}<br/>
                <v-radio-group
                v-model="infomedica.trasfundido"
                row
                :rules="[rules.required]"
                >
                <v-radio
                    :label="$t('Si')"
                    value="si"
                ></v-radio>
                <v-radio
                    label="No"
                    value="no"
                ></v-radio>
                </v-radio-group>
            </v-col>
            <v-col>
                {{$t('¿Padece de alguna de estas enfermedades?')}}<br/>
                <v-select
                        v-model="infomedica.padece_enfermedades"
                        attach
                        chips
                        multiple
                        :items="[
                        { text: $t('Asma'), value: 'asma'},
                        { text: $t('Falcemia'), value: 'falcemia' },
                        { text: $t('Leucemia'), value: 'leucemia' },
                        { text: $t('Epilepsia'), value: 'epilepsia' },
                        { text: $t('Amigdalitis'), value: 'amigdalitis' },
                        { text: $t('Otro'), value: 'otro' },
                        { text: $t('Ninguna'), value: 'ninguna' },
                        ]"
                        :label="$t('Especificar')+':'"
                        outlined
                        :rules="[rules.requiredMulti]"
                        ></v-select>
                        <v-text-field
                    v-if="infomedica.padece_enfermedades.includes('otro')"
                    v-model="infomedica.padece_enfermedades_otro"
                    label="Especificar Otro:"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
<v-col>
<v-radio-group
v-model="infomedica.autorizo1"
row
:rules="[rules.required]"
>
<v-radio
:label="$t('Autorizo al PCIS en caso de emergencia a transportar a mi hijo/a al centro médico que consideren en caso de no ser contactado/a.')"
value="si"
></v-radio>
</v-radio-group>
</v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-radio-group
                v-model="infomedica.autorizo2"
                row
                :rules="[rules.required]"
                >
                <v-radio
                    :label="$t('Autorizo al PCIS de aplicar a mi hijo/a el tipo de medicación necesaria en caso de alguna emergencia.')"
                    value="si"
                ></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-radio-group
                v-model="infomedica.firma"
                row
                :rules="[rules.required]"
                >
                <v-radio
                    :label="$t('Con la firma del presente documento declaro que la información que he suministrado al PCIS es fidedigna y autorizo al PCIS a verificar las mismas.')"
                    value="si"
                ></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                {{$t(`Favor mencione cualquier información u observación
                que considere relevante para poder satisfacer las
                necesidades académicas y emocionales de su hijo(a)
                (divorcios, pérdidas de seres queridos, mudanzas,
                dificultades académicas, etc.)`)}}:
                <v-textarea
                v-model="infomedica.observacion_relevante"
                outlined
                :rules="[rules.required]"
                :label="$t('Observación')+':'"
                v-uppercase
                ></v-textarea>
            </v-col>
        </v-row>
        </v-container>
        </v-form>
</template>

<script>
// import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'frmOtrasInformaciones',
  data() {
    return {
      loading: false,
      dialog: false,
      snackbar: false,
      errorMSG: '',
      archivoData: null,
      sangreOptions: [],
      infomedica: {
        salud: '',
        razonesPCIS: '',
        repetido_annio: '',
        expulsado: '',
        personalidad: '',
        caracteristicas_admira: '',
        tratamiento_medico: '',
        actividades: '',
        actitud_aprendizaje: '',
        conducta: '',
        info_adicional: '',
        pediatra_nombre: '',
        pediatra_telefono: '',
        grupo_sangre: '',
        enfermedades: [],
        enfermedades_anteriores: '',
        info_medadional: '',
        semana_embarazo: '',
        via_nacimiento: '',
        cantidad_hijos: '',
        numero_corresponde: '',
        antibiotico: '',
        antibiotico_especificar: '',
        anteojos: '',
        esquema_vacunacion: '',
        alergia_medicamento: '',
        alergia_medicamento_multi: [],
        alergia_medicamento_multi_otro: '',
        accidentes_tratamiento: '',
        accidentes_tratamientoEspef: '',
        alergia_familia: '',
        alergia_familiaEspef: '',
        medicamento_controlado: '',
        medicamento_controladoEspef: '',
        accidente_importante: '',
        accidente_importanteEspef: '',
        operado: '',
        operadoEspef: '',
        trasfundido: '',
        padece_enfermedades: [],
        padece_enfermedades_otro: '',
        observación_relevante: '',
      },
      inputEmergencia: [
        {
          nombre: '',
          parentesco: '',
          telefono: '',
        },
      ],
      emailRules: [
        (v) => !!v || this.$t('login.validateFrm.email'),
        (v) => /.+@.+\..+/.test(v) || this.$t('login.validateFrm.email_valid'),
      ],
      rules: {
        required: (value) => !!value || this.$t('login.validateFrm.required'),
        requiredMulti: (value) => value.length > 0 || this.$t('login.validateFrm.required'),
        maxGPCode: (v) => v?.length <= 8 || this.$t('admission.maxGPCode'),
        rulesFiles: (value) => !value || value.size < 2000000 || this.$t('El archivo debe ser inferior a 2 MB.'),
      },
    };
  },
  methods: {
    //   sangreOptions
    async getDataForm() {
      // Obtener los valores del formulario
      try {
        const { GRPSANGRE } = this.datosfrm;

        // Prefesion options
        const arrSangre = GRPSANGRE.map((v) => ({ text: v['/ISDFPS/BGRUP'], value: v['/ISDFPS/BGRUP'] }));
        this.sangreOptions = arrSangre;
      } catch (error) {
        console.log(error);
      }
    },
    addEmergencia() {
      this.inputEmergencia.push({
        nombre: '',
        parentesco: '',
        telefono: '',
      });
    },
    removeEmergencia(index) {
      this.inputEmergencia.splice(index, 1);
    },
  },
  mounted() {
    this.getDataForm();
  },
  computed: {
    ...mapGetters({ datosfrm: 'getDataForm' }),
  },
};
</script>

<style>

</style>
