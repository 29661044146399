import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VAppBar,{attrs:{"app":"","color":"brand-primary","height":"100"}},[_c('div',{staticClass:"d-flex align-center"},[_c('router-link',{attrs:{"to":"/"}},[_c(VImg,{staticClass:"shrink mr-2",attrs:{"alt":"Punta Cana International School","contain":"","max-height":"260","max-width":"260","src":"/assets/img/logo-puntacana-school.png"}})],1)],1),_c(VSpacer),_c('div',_vm._l((_vm.languages),function(entry){return _c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.$i18n.locale!==entry.language),expression:"$i18n.locale!==entry.language"}],key:entry.title,on:{"click":function($event){return _vm.changeLocale(entry.language)}}},[_c('flag',{attrs:{"iso":entry.flag,"squared":false}}),_vm._v(" "+_vm._s(entry.title)+" ")],1)}),0),(!_vm.user)?_c(VBtn,{attrs:{"text":""},on:{"click":_vm.btnRegister}},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t('general.register')))]),_c(VIcon,[_vm._v("mdi-account-plus")])],1):_vm._e(),(_vm.user)?_c(VBtn,{attrs:{"text":""},on:{"click":_vm.logout}},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t('general.logout')))]),_c(VIcon,[_vm._v("mdi-logout")])],1):_vm._e()],1),_c(VMain,[_c('router-view')],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }