import Vue from 'vue';
import FlagIcon from 'vue-flag-icon';
import VueMask from 'v-mask';
import Vuelidate from 'vuelidate';
import accounting from 'accounting';
import moment from 'moment';
import i18n from '@/i18n';
import * as Sentry from '@sentry/vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './axios.conf';

import '@/assets/css/gapp.css';

Vue.use(Vuelidate);

Sentry.init({
  Vue,
  dsn: 'https://8239282c59d4475ba6982c4d3914f7e2@o4505054094163968.ingest.sentry.io/4505054097833984',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

Vue.config.productionTip = false;

Vue.config.devtools = true;

Vue.directive('uppercase',
  {
    inserted(el, _, vnode) {
      el.addEventListener('input', async (e) => {
        e.target.value = e.target.value.toUpperCase();
        vnode.componentInstance.$emit('input', e.target.value.toUpperCase());
      });
    },
  });

Vue.use(VueMask);
Vue.use(FlagIcon);

Vue.filter('currency', (val) => accounting.formatMoney(val, ''));

Vue.filter('currencyNoDot', (val) => {
  const left = val.substring(0, val.length - 2);
  const right = val.substring(left.length);
  return accounting.formatMoney(`${left}.${right}`, '');
});

Vue.filter('date', (val) => moment(val).format('DD-MMM-YYYY'));

Vue.filter('number', (val) => Number(val));

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
