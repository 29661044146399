<template>
  <v-app id="inspire">
    <v-app-bar
      app
      color="white"
      height="120"
    >
      <div class="d-flex align-center">
        <router-link to="/">
        <v-img
        alt="Punta Cana International School"
        class="shrink mr-2"
        contain
        max-height="110"
        max-width="110"
        src="/assets/img/logo-PCIS.png"/>
        </router-link>
      </div>

      <v-spacer></v-spacer>
         <!-- <div>
    <v-select
      v-model="$i18n.locale"
      :items="locales"
      item-text="name"
      item-value="val"
      dense
    >
    </v-select>
  </div> -->
  <div>
    <button v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)"
    v-show="$i18n.locale!==entry.language">
        <flag :iso="entry.flag" v-bind:squared=false /> {{entry.title}}
    </button>
</div>
  <!-- <div class="locale-changer">
    <select v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
    </select>
  </div> -->
        <!-- <v-btn
          text
          @click="btnRegister"
          v-if="!user"
        >
          <span class="mr-2">{{ $t('general.register') }}</span>
          <v-icon>mdi-account-plus</v-icon>
        </v-btn>
        <v-btn
          text
          @click="logout"
          v-if="user"
        >
          <span class="mr-2">{{ $t('general.logout') }}</span>
          <v-icon>mdi-logout</v-icon>
        </v-btn> -->
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container>
       <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AppLayout',
  data() {
    return {
      selectedItem: 1,
      languages: [
        { flag: 'es', language: 'es', title: 'Español' },
        { flag: 'us', language: 'en', title: 'English' },
      ],
    };
  },
  methods: {
    changeLocale(lang) {
      this.$i18n.locale = lang;
      this.$vuetify.lang.current = lang;
      window.localStorage.setItem('currentLanguage', lang);
    },
    logout() {
      this.$store.dispatch('user', null);
      this.$router.push('/');
    },
    btnRegister() {
      this.$router.push('/register');
    },
    btnLink() {
      this.$router.push('/dashboard');
    },
    btnLinkAdmission() {
      this.$router.push('/admission');
    },
  },
  computed: {
    ...mapGetters(['user']),
  },
};
</script>
