<template>
 <v-form ref="frmTutor">
        <v-container fluid>
        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="3"
          >
        <!-- <v-switch
              v-model="tutor.empleado"
              :label="`Empleado Del Grupo Puntacana: ${tutor.empleado ? 'Si':'No'}`"
              color="info"
              hide-details
            ></v-switch> -->
            <v-switch
              v-model="tutor.egresado"
              :label="`${$t('Egresado del')} PCIS/CEC: ${tutor.egresado ? $t('Si'):'No'}`"
              color="info"
              :hint="`${$t('Egresado del')} Puntacana International School
              / Centro Educativo Caracolí.`"
              persistent-hint
            ></v-switch>
          </v-col>
          <v-col
                cols="12"
                md="3">
                <v-select
                    v-model="tutor.tipoId"
                    :items="tipoIdOptions"
                    :label="$t('Tipo de ID')+':'"
                    outlined
                    :rules="[rules.required]"
                    return-object
                    ></v-select>
            </v-col>
            <v-col
                cols="12"
                md="6">
                <v-text-field
                    v-model="tutor.identificacion"
                    :label="$t('Número de Identificación (ID)')+':'"
                    outlined
                    @keyuppp.enter="getColaborador"
                    @blurppp="getColaborador"
                    :rules="[rules.required]"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pd-4">
                <v-text-field
                    v-model="tutor.nombre"
                    :label="$t('Nombre')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
                <v-text-field
                    v-model="tutor.apellidos"
                    :label="$t('Apellidos')+':'"
                    outlined
                    :rules="[rules.required]"
                    v-uppercase
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
             <v-col cols="6" md="3">
                 <v-autocomplete
                    v-model="tutor.nacionalidad"
                    :items="nacionalidadOptions"
                    :label="$t('Nacionalidad')+':'"
                    outlined
                    :rules="[rules.required]"
                    return-object
                    ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="3">
                 <v-autocomplete
                    v-model="tutor.profesion"
                    :items="profesionOptions"
                    :label="$t('Profesión')+':'"
                    outlined
                    :rules="[rules.required]"
                    return-object
                    ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field
                    v-model="tutor.lugar_trabajo"
                    :label="$t('Lugar de trabajo')+':'"
                    outlined
                    v-uppercase
                ></v-text-field>
            </v-col>
            <v-col cols="6" md="3">
                <v-text-field
                    v-model="tutor.trabajo_tel"
                    :label="$t('Teléfono del trabajo')+':'"
                    outlined
                    v-mask="'(###)-###-####'"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
             <v-col cols="3">
                <v-text-field
                    v-model="tutor.casa_tel"
                    :label="$t('Teléfono casa')+':'"
                    outlined
                    v-mask="'(###)-###-####'"
                ></v-text-field>
            </v-col>
            <v-col cols="3">
                <v-text-field
                    v-model="tutor.celular"
                    :label="$t('Celular')+':'"
                    outlined
                    v-mask="'(###)-###-####'"
                    :rules="[rules.required]"
                ></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="tutor.email"
                    label="E-mail:"
                    outlined
                    :rules="emailRules"
                    v-uppercase
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card-title class="text-h5 blue lighten-5">
                    {{ $t('Dirección') }}:
                </v-card-title>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                 <v-autocomplete
                    v-model="tutor.direccion.pais"
                    :items="paisOptions"
                    :label="$t('País')+':'"
                    outlined
                    :rules="[rules.required]"
                    return-object
                    :readonly="tipo ==='madre' && estudiante_vive === 'padres'"
                    ></v-autocomplete>
            </v-col>
             <v-col cols="3">
                 <v-autocomplete
                    v-model="tutor.direccion.poblacion"
                    :items="poblacionOptions"
                    :label="$t('Población')+':'"
                    outlined
                    :rules="[rules.required]"
                    return-object
                    :readonly="tipo ==='madre' && estudiante_vive === 'padres'"
                    ></v-autocomplete>
            </v-col>
             <v-col v-if="tutor.direccion.poblacion.value === 'OTRA'">
                 <v-text-field
                    v-model="tutor.direccion.otra_poblacion"
                    :label="$t('Otra Población')+':'"
                    outlined
                    v-uppercase
                    :readonly="tipo ==='madre' && estudiante_vive === 'padres'"
                ></v-text-field>
            </v-col>
            <v-col>
                 <v-text-field
                    v-model="tutor.direccion.calle"
                    :label="$t('Calle')+':'"
                    outlined
                    v-uppercase
                    :readonly="tipo ==='madre' && estudiante_vive === 'padres'"
                ></v-text-field>
            </v-col>
             <v-col cols="1">
                 <v-text-field
                    v-model="tutor.direccion.numero"
                    label="No.:"
                    outlined
                    :readonly="tipo ==='madre' && estudiante_vive === 'padres'"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="6"
          >
            <v-file-input
            :rules="[rules.rulesFiles]"
            id="documentoID"
            :label="$t('Documento de Identificación')"
            outlined
            show-size
            accept=".png, .jpg, .jpeg, .pdf"
            prepend-icon="mdi-file-upload"
            @change="handleImage"
          ></v-file-input>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="4"
          >
          </v-col>
        </v-row>
        <v-snackbar
      v-model="snackbar">
      {{ errorMSG }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
        </v-container>
        </v-form>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'DatosTutorForm',
  props: {
    refs: String,
    tutorPrincipal: String,
    tipo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      snackbar: false,
      errorMSG: '',
      archivoData: null,
      archivoDataTipo: null,
      // tipoIdOptions: [
      //   { text: 'Cédula', value: 1 },
      //   { text: 'Pasaporte', value: 2 },
      // ],
      paisOptions: [],
      nacionalidadOptions: [],
      profesionOptions: [],
      poblacionOptions: [],
      tutor: {
        empleado: false,
        egresado: false,
        pernr: '',
        kunnr: '',
        bp_number: '',
        codColaborador: '',
        tipoId: '',
        identificacion: '',
        nombre: '',
        apellidos: '',
        nacionalidad: '',
        profesion: '',
        lugar_trabajo: '',
        trabajo_tel: '',
        casa_tel: '',
        celular: '',
        email: '',
        direccion: {
          calle: '',
          numero: '',
          pais: '',
          poblacion: '',
          regio: '02', // Valor fijo de la region
        },
      },
      emailRules: [
        (v) => !!v || this.$t('login.validateFrm.email'),
        (v) => /.+@.+\..+/.test(v) || this.$t('login.validateFrm.email_valid'),
      ],
      rules: {
        required: (value) => !!value || this.$t('login.validateFrm.required'),
        rulesFiles: (value) => {
          if (typeof value !== 'undefined') {
            const validExt = ['jpg', 'jpeg', 'png', 'pdf'];
            const extFile = value !== null ? value.name.split('.').pop().toLowerCase() : '';
            if (validExt.includes(extFile)) {
              if (value.size < 2000000) {
                return true;
              }
              return 'El archivo debe ser inferior a 2 MB.';
            }
            return `Archivo no valido formatos permitidos ${validExt.join(', ')}`;
          }
          if (!this.getSolcupo.error) { return true; }
          return this.$t('login.validateFrm.required');
        },
      },
    };
  },
  watch: {
    tutor(value) {
      if (value !== 'otros') {
        this.tutorOtro = '';
      }
    },
    datosfrm() {
      this.getDataForm();
    },
    // '$i18n.locale': function lang(newVal) {
    //   this.getDataForm();
    //   return newVal;
    //   // console.log('locale change', newVal);
    // },
    /* 'tutor.empleado': function empleado(newVal) {
      if (!newVal) {
        this.tutor.tipoId = '';
        this.tutor.codColaborador = '';
        return;
      }
      // Seteo el tipo de id si es colaborador
      this.tutor.tipoId = 3;
      this.tutor.identificacion = ''; // Limpio el campo de identificacion
    }, */
    estudiante_vive() {
      this.setAmbosPadresDataForms();
    },
    // 'tutor.direccion': function direccion() {
    //   console.log('Cambio direccion');
    //   if (this.tipo === 'padre') {
    //     this.padre_direccion(this.tutor.direccion);
    //   }
    // },
  },
  methods: {
    ...mapMutations(['set_padre_direccion']),
    async handleImage(file) {
      // const selectedImages = e;
      if (file) {
        this.archivoDataTipo = file.type.split('/')[1].toUpperCase();
        await this.createBase64Images(file);
      }
    },
    async createBase64Images(imageObject) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.archivoData = e.target.result;
      };
      await reader.readAsDataURL(imageObject);
    },
    async getDataForm() {
    //   console.log(this.datosfrm);
      // Obtener los valores del formulario
      try {
        const { PAISES, FORMACION, LOCALIDAD } = this.datosfrm;
        // Paises options
        const arrPaises = PAISES.map((pais) => ({ text: pais.LANDX, value: pais.LAND1 }));
        arrPaises.sort((a, b) => {
          const nameA = a.text.toUpperCase(); // ignore upper and lowercase
          const nameB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        this.paisOptions = arrPaises;

        // Nacionalidad options
        const arrNac = PAISES.map((v) => ((v.NATIO !== '')
          ? { text: `${v.NATIO} - ${v.LAND1}`, value: v.LAND1 }
          : { text: v.NATIO, value: v.LAND1, disabled: true }));
        arrNac.sort((a, b) => {
          const nameA = a.text.toUpperCase(); // ignore upper and lowercase
          const nameB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        this.nacionalidadOptions = arrNac;

        // Prefesion options
        const arrProf = FORMACION.map((v) => ((v.ATEXT !== '')
          ? { text: v.ATEXT, value: v.AUSBI }
          : { text: v.ATEXT, value: v.AUSBI, disabled: true }));
        arrProf.sort((a, b) => {
          const nameA = a.text.toUpperCase(); // ignore upper and lowercase
          const nameB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        this.profesionOptions = arrProf;

        // Poblacion options
        const arrPoblac = LOCALIDAD.map((v) => ((v.ATEXT !== '')
          ? { text: v.LOCALIDAD, value: v.ID_LOCALIDAD }
          : { text: v.LOCALIDAD, value: v.ID_LOCALIDAD, disabled: true }));
        arrProf.sort((a, b) => {
          const nameA = a.text.toUpperCase(); // ignore upper and lowercase
          const nameB = b.text.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        this.poblacionOptions = arrPoblac;

        //   console.log(arrPaises);
        // console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async getColaborador() {
      const tutorPrincipal = this.$store.state.tutor_principal;
      // Obtener los valores del formulario
      if (
        (tutorPrincipal === 'madre' && this.tipo === 'madre') || (tutorPrincipal === 'padre' && this.tipo === 'padre')
        || (tutorPrincipal === 'madredda' && this.tipo === 'madre') || (tutorPrincipal === 'madredvc' && this.tipo === 'madre')
        || (tutorPrincipal === 'padreddo' && this.tipo === 'padre') || (tutorPrincipal === 'padredvc' && this.tipo === 'padre')
        || (tutorPrincipal === 'otros' && this.tipo === 'tutorOtro')
      ) {
        const refPrincipal = { padre: 'padrefrm', madre: 'madrefrm', otros: 'otrosfrm' };
        const refSl = refPrincipal[this.tipo];
        // console.log(tutorPrincipal);
        try {
          this.loading = true;
          this.dialog = true;
          const { data } = await axios.get(`api/formadmission/searchcliente?type=${this.tutor.tipoId.value}&identification=${this.tutor.identificacion}`);
          const { KUNNR, PERNR, BP_NUMBER } = data;

          /* -------------------- set info del cliente o empleado en local -------------------- */
          localStorage.setItem('tutor_principal', JSON.stringify({
            KUNNR, PERNR, BP_NUMBER, TIPO: this.tipo, REF: refSl,
          }));

          this.tutor.pernr = PERNR;
          this.tutor.kunnr = KUNNR;
          this.tutor.bp_number = BP_NUMBER;

          // Setear los datos de cliente o empleado por tipo de documento y tutor principal
        //   console.log(data);
        } catch (error) {
          this.errorMSG = 'No encontrado.';
          // this.snackbar = true;
          console.log(error);
        }

        this.loading = false;
        this.dialog = false;
      }
      // setTimeout(() => {
      //   this.loading = false;
      //   this.dialog = false;
      // }, 3000);
    },
    setAmbosPadresDataForms() {
      console.log(this.estudiante_vive);
      /*  if (this.tipo === 'madre' && this.estudiante_vive === 'padres') {
      // Validar si ha enviado alguna solicitud
        if (typeof this.resp_solcupo === 'object' && 'SOLCUPO_ENVIADA' in this.resp_solcupo) {
        // Obtener la direccion de la ultima solicitud enviada
          const padreDireccion = this.resp_solcupo.SOLCUPO_ENVIADA;
          console.log(padreDireccion);
        }

        // Todo: autocompletar la direccion de la madre con la del padre si vive con ambos padres
        this.tutor.direccion.pais = { text: 'Rep.Dominicana', value: 'DO' };
      } */

      if (typeof this.resp_solcupo === 'object' && 'SOLCUPO_ENVIADA' in this.resp_solcupo) {
        const padreData = this.resp_solcupo.SOLCUPO_ENVIADA.json.padre;
        const madreData = this.resp_solcupo.SOLCUPO_ENVIADA.json.madre;
        // Obtener la direccion de la ultima solicitud enviada
        if (this.tipo === 'padre') {
          this.tutor = padreData;
        } else if (this.tipo === 'madre') {
          this.tutor = madreData;
          // Validar si vive junto con el padre y usar la direccion del padre
          if (this.estudiante_vive === 'padres') {
            this.tutor.direccion = padreData.direccion;
          }
        }
      } else if (this.estudiante_vive === 'padres' && this.tipo === 'padre') {
        // Sin solicitud enviada la misma direccion si estudiante vive con padres
        // State direccion del padre
        this.set_padre_direccion(this.tutor.direccion);
      } else if (this.estudiante_vive === 'padres' && this.tipo === 'madre') {
        // Sin solicitud enviada la misma direccion si estudiante vive con padres
        // Set direccion padre a la madre
        this.tutor.direccion = this.padre_direccion;
      }
    },
  },
  mounted() {
    // console.log(this.tipo);
    // console.log(this.estudiante_vive);
    // const estudianteVive = this.$store.state.estudiante_vive;
    // console.log(this.$store.state.estudiante_vive);
    this.setAmbosPadresDataForms();
    this.getDataForm();
  },
  computed: {
    ...mapGetters({ datosfrm: 'getDataForm' }),
    ...mapGetters(['getSolcupo']),
    ...mapState(['estudiante_vive', 'resp_solcupo', 'padre_direccion']),

    tipoIdOptions() {
      return [
        { text: this.$t('Cédula'), value: 1 },
        { text: this.$t('Pasaporte'), value: 2 },
      ];
    },
    // rulesPhone() {
    //   return () =>
    // (this.tutor.celular.length > 0 ||
    // this.tutor.casa_tel.length > 0) ||
    // this.$t('admission.rulesPhone');
    // },
  },
};
</script>

<style>
.custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }

</style>
