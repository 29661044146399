import Vue from 'vue';
import VueI18n from 'vue-i18n';

// Idiomas
import langEs from '../locales/es.json';
import langEn from '../locales/en.json';

Vue.use(VueI18n);

const messages = {
  en: langEn,
  es: langEs,
};

const i18n = new VueI18n({
  locale: 'es', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
});

export default i18n;
