import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/i18n/messages';

Vue.use(VueI18n);

const locale = window.localStorage.currentLanguage ? window.localStorage.currentLanguage : 'es';

export default new VueI18n({
  locale,
  messages,
  fallbackLocale: 'es',
  silentTranslationWarn: true,
});
