<template>
  <v-card
    class="mx-auto"
    max-width="800"
  >
    <v-card-text class="text-center">
      <p class="text-h4 text--primary">
        Muchas Gracias
      </p>
      <div class="text--primary">
        <p class="subtitle-1 font-weight-bold">{{ resp_solcupo.DETAIL }}</p>
        <v-btn color="success" @click="handleClick()">Crear otra inscripción</v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

import { mapState } from 'vuex';

export default {

  methods: {
    handleClick() {
      this.$router.push({ name: 'home' });
    },

  },

  computed: {
    ...mapState(['resp_solcupo']),
  },

};
</script>

<style>

</style>
